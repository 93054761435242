import React from 'react'
import { Pie } from 'react-chartjs-2'; 

function SyncListHoldChart() {

    const data = {
        labels: [],
        datasets: [
            {
                label: 'Bridges Per Global',
                data: [74, 20, 6, 1],
                backgroundColor: [
                    'rgba(45, 206, 86, 1)', 
                    'rgba(45, 255, 008, 1)', 
                    'rgba(30, 206, 186, 1)',
                    'rgba(140, 255, 86, 1)',
                    'rgba(245, 206, 186, 0.5)',
                    'rgba(45, 106, 246, 0.5)',
                    'rgba(145, 106, 86, 0.5)',
                    'rgba(45, 06, 186, 0.5)',
                    'rgba(145, 57, 25, 0.5)',
                    'rgba(145, 06, 186, 0.5)',
                    'rgba(05, 106, 186, 0.5)'],

            }
        ]
    }

    const options = {
        title: {
            display: true, 
            text: 'Holds',
            fontSize: 30,
            fontColor: "black"
        },
        responsive: true,
    
    }

    return (
        <div className="chart-size" >
            <Pie data={data} options={options} height="250"/>
        </div>
    )
}

export default SyncListHoldChart;