import React, { createContext, useContext, useState } from "react";

import { BridgesContext } from "./Bridges.context";

export const PeersContext = createContext();

const PeersContextProvider = (props) => {
  const [peersList, setPeersList] = useState([
    {
      isLoading: true,
      isError: false,
      isClicked: false,
      bridge: 0,
      sync: 0,
      peersData: [],
    },
  ]);
  return (
    <PeersContext.Provider value={{ peersList, setPeersList }}>
      {props.children}
    </PeersContext.Provider>
  );
};

export const useFetchPeerList = () => {
  const { peersList, setPeersList } = useContext(PeersContext);
  const { bridges } = useContext(BridgesContext);
  let newIndex = -1;

  const fetchPeers = async (blockNumber, syncNumber) => {
    bridges.map((bridge, index) => {
      bridge.bridgeData.map((item) => {
        if (item.BlockNumber == blockNumber) {
          newIndex = index;
        }
      });
    });
    try {
      const setGlobal = await fetch(
        `https://api.philos.us:42074/global/${bridges[newIndex].global}`
      );
      const setBridge = await fetch(
        `https://api.philos.us:42074/bridge/${blockNumber}`
      );
      const setSync = await fetch(
        `https://api.philos.us:42074/psl/${syncNumber}`
      );
      const resPeers = await fetch(`https://api.philos.us:42074/peers/`);
      const peersData = await resPeers.json();

      if (peersList.isLoading) {
        console.log("Loading peers...");
      }
      if (peersData != null && peersData != "{Error: PNF}") {
        setPeersList([
          ...peersList,
          {
            isLoading: false,
            isError: false,
            isClicked: false,
            bridge: blockNumber,
            sync: syncNumber,
            peersData: peersData,
          },
        ]);
      } else {
        setPeersList([
          ...peersList,
          {
            isLoading: false,
            isError: false,
            isClicked: false,
            bridge: blockNumber,
            sync: syncNumber,
            peersData: [],
          },
        ]);
      }
    } catch (error) {
      console.log(error);
      setPeersList([
        ...peersList,
        {
          isLoading: false,
          isError: error,
          isClicked: false,
          bridge: 0,
          sync: 0,
          peersData: [],
        },
      ]);
    }
  };
  return {
    peersList: peersList,
    fetchPeers: fetchPeers,
    setPeersList: setPeersList,
  };
};
export default PeersContextProvider;
