import React, { useEffect, useState, useContext } from "react";
import { Redirect } from "react-router-dom";

// ***** IMPORT COMPONENTS *****
import SmallCard from "../../components/smallCard/SmallCard.component";
import SyncListTrustChart from "../../components/charts/syncList/SyncListTrustChart";
import SyncListCreditsChart from "../../components/charts/syncList/SyncListCreditsChart";
import SyncListHoldChart from "../../components/charts/syncList/SyncListHoldChart";

// ***** IMPORT CONTEXT *****
import { SyncListContext } from "../../contexts/SyncList.context";
import { BridgesContext } from "../../contexts/Bridges.context";
import { SelectedBlockContext } from "../../contexts/SelectedBlock.context";

// ***** IMPORT NAMING *****
import { GLOBAL_NAMING } from "../../data/globalNaming.data";

// ***** IMPORT STYLES *****
import "./SyncListView_1.scss";

// ***** TEMPORARY IMPORTS *****
import GLOBAL_STATS from "../../data/globalStats.data";

const SyncList_1 = (props) => {
  let currentBridge = { global: 0, bridge: {} };
  let currentSync = { bridge: 0, sync: {} };
  const syncLists = useContext(SyncListContext);
  const [currentDeserialized, setCurrentDeserialized] = useState();
  const bridges = useContext(BridgesContext);
  const selectedBlock = useContext(SelectedBlockContext);
  const [currentBridgeInfo, setCurrentBridgeInfo] = useState({
    peerCount: 0,
    phyliTransferred: 0,
    globalHoldMargin: 0,
    bridgeHoldMargin: 0,
    cumulativeBridgeTrust: 0,
    globalPLPercentage: 0,
  });

  syncLists.syncLists.map((sync) => {
    sync.syncData.map((syncItem) => {
      if (
        syncItem.SyncListNumber ==
        selectedBlock.selectedBlock.item.SyncListNumber
      ) {
        currentSync = { bridge: sync.bridge, syncData: syncItem };
      }
    });
  });

  bridges.bridges.map((bridge) => {
    bridge.bridgeData.map((bridgeItem) => {
      if (bridgeItem.BlockNumber == currentSync.bridge) {
        currentBridge = { global: bridge.global, bridge: bridgeItem };
      }
    });
  });

  const fetchDeserialized = async (global, blockNumber) => {
    if (blockNumber != undefined) {
      const setGlobal = await fetch(
        `https://api.philos.us:42074/global/${global}`
      );
      const setBridge = await fetch(
        `https://api.philos.us:42074/bridge/${blockNumber}`
      );
      const bridgeRes = await fetch(
        "https://api.philos.us:42074/bridge/deserialized"
      );
      const bridgeDeserialized = await bridgeRes.json();

      if (bridgeDeserialized != undefined) {
        let peerCount = 0;
        let trustTotal = 0;
        let phyliTransferred = 0;
        let totalBalance = 0;
        let totalHold = 0;
        let globalData = {};
        GLOBAL_STATS.map((item) => {
          if (item.BlockNumber == currentBridge.global) {
            globalData = item;
          }
        });
        if (bridgeDeserialized.BlockType == 5) {
          bridgeDeserialized.PeerStates.map((peer) => {
            peerCount += 1;
            trustTotal += peer.SyncTrust + 1;
            phyliTransferred += peer.Stamps;
            totalBalance += peer.Balance;
            totalHold += peer.Hold;
          });
          console.log(currentBridge);
          console.log(totalHold);
          setCurrentBridgeInfo({
            ...currentBridgeInfo,
            peerCount: peerCount,
            phyliTransferred: phyliTransferred,
            globalHoldMargin: (
              (totalHold / globalData.GlobalPhyliBal) *
              100
            ).toFixed(2),
            bridgeHoldMargin: ((totalHold / totalBalance) * 100).toFixed(2),
            cumulativeBridgeTrust: trustTotal,
            globalPLPercentage: ((peerCount / 6) * 100).toFixed(2),
          });
        } else if (bridgeDeserialized.BlockType == 6) {
          bridgeDeserialized.Payload.FinalPeerStates[0].map((peer) => {
            peerCount += 1;
            trustTotal += peer.SyncTrust + 2;
            phyliTransferred += peer.Stamps;
            totalBalance += peer.Balance;
            totalHold += peer.Hold;
          });
          console.log(currentBridge);
          console.log(globalData);
          setCurrentBridgeInfo({
            ...currentBridgeInfo,
            peerCount: peerCount,
            phyliTransferred: phyliTransferred,
            globalHoldMargin: (
              (totalHold / globalData.GlobalPhyliBal) *
              100
            ).toFixed(2),
            bridgeHoldMargin: ((totalHold / totalBalance) * 100).toFixed(2),
            cumulativeBridgeTrust: trustTotal,
            globalPLPercentage: ((peerCount / 6) * 100).toFixed(2),
          });
        } else if (bridgeDeserialized.BlockType == 7) {
          bridgeDeserialized.BridgeState.DivisionStates[0].ActivePeerStates.map(
            (peer) => {
              peerCount += 1;
              trustTotal += peer.SyncTrust + 3;
              phyliTransferred += peer.Stamps;
              totalBalance += peer.Balance;
              totalHold += peer.Hold;
            }
          );
          console.log(totalHold);
          setCurrentBridgeInfo({
            ...currentBridgeInfo,
            peerCount: peerCount,
            phyliTransferred: phyliTransferred,
            globalHoldMargin: (
              (totalHold / globalData.GlobalPhyliBal) *
              100
            ).toFixed(2),
            bridgeHoldMargin: ((totalHold / totalBalance) * 100).toFixed(2),
            cumulativeBridgeTrust: trustTotal,
            globalPLPercentage: ((peerCount / 6) * 100).toFixed(2),
          });
        }
      }
    }
    return;
  };

  try {
    // ***** FETCH DESERIALIZED BRIDGE USING FETCH FUNCTION ****
    useEffect(() => {
      fetchDeserialized(currentBridge.global, currentSync.bridge);
      // setDeserialized(currentDeserialized);
      return;
    }, [currentBridge.BlockNumber]);
    console.log(currentBridgeInfo);
  } catch (error) {}
  if (currentSync.bridge == 0) {
    return (
      <div className='syncList-view'>
        <h1>Data Failed to Load...</h1>
        <Redirect to='/' />
      </div>
    );
  }
  return (
    <div className='syncList-view'>
      <div className='card-row'>
        <SmallCard
          className='card card-12'
          title='Blocks Last 24hrs'
          content='205'
        />
        <SmallCard
          className='card card-12'
          title='Global Hold Margin'
          content={`${currentBridgeInfo.globalHoldMargin}%`}
        />
        <SmallCard
          className='card card-12'
          title='Hold Margin'
          content={`${currentBridgeInfo.bridgeHoldMargin}%`}
        />
        <SmallCard
          className='card card-12'
          title='Cumulative Trust'
          content={`${currentBridgeInfo.cumulativeBridgeTrust}`}
        />
        <SmallCard
          className='card card-12'
          title='Number of Ledgers'
          content={`${currentBridgeInfo.peerCount}`}
        />
        <SmallCard
          className='card card-12'
          title='Trust ST Deviation'
          content='+1.87%'
        />
        <SmallCard
          className='card card-12'
          title='License Type'
          content='Substation'
        />
      </div>
      <div className='syncList-container'>
        <div className='syncList-left'>
          <div className='sync-banner'>
            <h2>{`Requesting SyncList:${currentSync.syncData.SyncListNumber}`}</h2>
            <span>{`from Bridge ${currentSync.bridge}`}</span>
            <h3>{`23,900 Phyli`}</h3>
          </div>
          <div className='buttons-row'>
            <button className='selected'>{`${currentSync.syncData.SyncListNumber}`}</button>
            <button>0071</button>
            <button>0886</button>
            <button>0034</button>
          </div>
          <div className='charts-row'>
            <div className='chart-holder'>
              <SyncListTrustChart />
            </div>
            <div className='chart-holder'>
              <SyncListCreditsChart />
            </div>
            <div className='chart-holder'>
              <SyncListHoldChart />
            </div>
          </div>
        </div>
        <div className='syncList-right'>
          <img src={GLOBAL_NAMING.syncListView.image} />
          <h2>Block Distribution</h2>
          <div className='dist-banner'>
            <p>{`Sync List Leader: ${currentSync.syncData.SyncListNumber}`}</p>
            <p>{`Registered Name: Global Solar, Inc`}</p>
            <p>{`License Type: 234`}</p>
            <p>{`Date SL Created: DT Stamp`}</p>
            {/* <p className='p-center'>Peer Ledgers</p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SyncList_1;
