import React, { useState, useContext } from "react";
import { NavLink, withRouter, Redirect } from "react-router-dom";
import * as RiIcons from "react-icons/ri";

// ***** STYLE *****
import "./Navigation.styles.scss";

// ***** CONTEXT *****
import { GlobalsContext } from "../../contexts/globals.context";
import { useFetchBridges } from "../../contexts/Bridges.context";
import { useGetSelectedBlock } from "../../contexts/SelectedBlock.context";
import { useFetchSyncList } from "../../contexts/SyncList.context";
import { useFetchPrimary } from "../../contexts/Primary.context";

// ***** MENU **** NOT IN USE **** *****
import BridgeMenu from "../bridgeMenu/BridgeMenu.component";
import SubMenu from "./SubMenu.component";
import { SidenavData } from "../../data/sideNav.data";

const Navigation = () => {
  const [configArrow, setConfigArrow] = useState(true);
  const [consensusArrow, setConsensusArrow] = useState(false);
  const [bridgeArrow, setBridgeArrow] = useState({ item: -1, index: -1 });
  const [syncArrow, setSyncArrow] = useState({ item: -1, index: -1 });
  const [peersArrow, setPeersArrow] = useState({ item: -1, index: -1 });
  const { globals, selectedGlobal, setSelectedGlobal } = useContext(GlobalsContext);
  const [globalSelected, setGlobalSelected] = useState();
  const [bridgeSelected, setBridgeSelected] = useState();
  const [syncSelected, setSyncSelected] = useState();
  const [peerSelected, setPeerSelected] = useState();
  const { bridges, getBridges, setBridges } = useFetchBridges();
  const { selectedBlock, getSelectedBlock } = useGetSelectedBlock();
  const { syncLists, fetchSync, setSyncLists, peersLists, setPeersLists } = useFetchSyncList();
  const { fetchPrimary, primaryList } = useFetchPrimary();

  // ***** GLOBAL SELECTED FUNCTIONS *****
  const handleSelected = async (evt) => {
    await setSelectedGlobal(evt);
    if (bridges.find((item) => item.global == evt)) {
      return;
    }
    getBridges(evt);
  };

  // ***** BRIDGE ARROW FUNCTIONS *****
  const handleBridgeArrow = (evt) => {
    let updatedBridges = bridges.map((item) => {
      if (item.global == evt && !item.isClicked) {
        return { ...item, isClicked: true };
      } else {
        return { ...item, isClicked: false };
      }
    });
    return setBridges(updatedBridges);
  };

  const bridgeArrowSelected = (evt) => {
    bridges.map((item, index) => {
      if (item.global == evt) {
        setBridgeArrow({ item: evt, index: index });
      }
      return bridgeArrow;
    });
  };

  // ***** BRIDGE ITEM SELECTED FUNCTIONS *****
  const handleBridgeSelected = async (evt) => {
    await setBridgeSelected(evt);
    if (syncLists.find((item) => item.bridge == evt)) {
      return;
    }
    fetchSync(evt);
  };

  // ***** SYNC ARROW FUNCTIONS *****
  const handleSyncArrow = (evt) => {
    let updatedSyncLists = syncLists.map((item) => {
      if (item.bridge == evt && !item.isClicked) {
        return { ...item, isClicked: true };
      } else {
        return { ...item, isClicked: false };
      }
    });
    return setSyncLists(updatedSyncLists);
  };

  const syncArrowSelected = (evt) => {
    syncLists.map((item, index) => {
      if (item.bridge == evt) {
        setSyncArrow({ item: evt, index: index });
      }
      return syncArrow;
    });
  };

  // ***** SYNC SELECTED FUNCTIONS *****
  const handleSyncSelected = async (evt) => {
    await setSyncSelected(evt);
    // fetchPeers(bridgeSelected, evt);
  };

  // ***** PEER ARROW FUNCTIONS *****
  const handlePeerArrow = (evt) => {
    let updatedPeersLists = peersLists.map((item) => {
      if (item.syncNumber == evt && !item.isClicked) {
        return { ...item, isClicked: true };
      } else {
        return { ...item, isClicked: false };
      }
    });
    return setPeersLists(updatedPeersLists);
  };

  const peerArrowSelected = (evt) => {
    peersLists.map((item, index) => {
      if (item.syncNumber == evt) {
        setPeersArrow({ item: evt, index: index });
      }
      return peersArrow;
    });
  };

  // ***** PEER SELECTED FUNCTION *****

  const handlePeerSelected = async (evt) => {
    const bridgePeer = await evt[1].split(",");
    setPeerSelected(bridgePeer[1]);
    fetchPrimary(bridgePeer[0], bridgePeer[1]);
  };

  return (
    <div>
      <ul>
        <li className='nav-link'>
          {/* *******************************************
           ***** CONFIGURAGATION LABEL AND ARROWS *****
           ******************************************* */}
          Configuration{" "}
          {!configArrow ? (
            <RiIcons.RiArrowRightSFill
              className='nav-arrow'
              onClick={() => {
                setConfigArrow(!configArrow);
              }}
            />
          ) : (
            <RiIcons.RiArrowDownSFill
              className='nav-arrow'
              onClick={() => {
                setConfigArrow(!configArrow);
              }}
            />
          )}
        </li>
        <li>
          {/* *****************************
           ***** GLOBAL PARAMETERS *****
           ***************************** */}
          {configArrow ? (
            <NavLink
              className={`nav-link sub-nav ${selectedGlobal == 0 ? "selected" : null}`}
              id={0}
              to='/'
              exact
              onClick={(evt) => {
                setSelectedGlobal(evt.currentTarget.id);
                setGlobalSelected(evt.currentTarget.id);
                handleBridgeArrow(evt.currentTarget.id);
                setBridgeArrow({ item: -1, index: -1 });
                setBridgeSelected();
              }}
            >
              Parameters
            </NavLink>
          ) : null}
        </li>
        {/* **************************************
         ***** CONSENSUS LABEL AND ARROWS *****
         ************************************** */}
        <li className='nav-link'>
          Consensus{" "}
          {!consensusArrow && !globals.isLoading ? (
            <RiIcons.RiArrowRightSFill
              className='nav-arrow'
              onClick={() => {
                setConsensusArrow(!consensusArrow);
              }}
            />
          ) : consensusArrow && !globals.isLoading ? (
            <RiIcons.RiArrowDownSFill className='nav-arrow' onClick={() => setConsensusArrow(!consensusArrow)} />
          ) : null}
        </li>
        {/* **************************
         ***** DISPLAY GLOBAL *****
         ************************** */}
        {globals.isLoading != true
          ? consensusArrow
            ? globals.globalData.map((global) => {
                return (
                  <div>
                    {/* **********************************
                     ***** DISPLAY BRIDGE ITEM UP *****
                     ********************************** */}
                    <li>
                      {bridgeArrow.item == global.BlockNumber
                        ? bridges[bridgeArrow.index].bridgeData.map((bridge) => {
                            return (
                              <div>
                                {/* ********************************
                                 ***** DISPLAY SYNC ITEM UP *****
                                 ******************************** */}
                                <li className='nav-link'>
                                  {syncArrow.item == bridge.BlockNumber
                                    ? syncLists[syncArrow.index].syncData.map((sync) => {
                                        return (
                                          <div>
                                            <div className='nav-link'>
                                              <NavLink
                                                className={`nav-link sync-sub ${
                                                  sync.SyncListNumber == syncSelected ? "selected" : null
                                                }`}
                                                key={sync.SyncListNumber}
                                                id={sync.SyncListNumber}
                                                to='/syncListView'
                                                exact
                                                onClick={(evt) => {
                                                  handleSyncSelected(evt.currentTarget.id);
                                                  getSelectedBlock(evt.currentTarget.id);
                                                  setBridgeSelected();
                                                  setGlobalSelected();
                                                  setPeerSelected();
                                                }}
                                              >
                                                {`Sync: ${sync.SyncListNumber}`}
                                              </NavLink>
                                              {/* ********************************
                                               ***** PEERLIST ITEM ARROWS *****
                                               ******************************** */}
                                              {peersLists.some(
                                                (item) =>
                                                  !item.isClicked &&
                                                  item.syncNumber == sync.SyncListNumber &&
                                                  item.peerData.length != 0
                                              ) ? (
                                                <RiIcons.RiArrowRightSFill
                                                  id={sync.SyncListNumber}
                                                  className='nav-arrow'
                                                  onClick={(evt) => {
                                                    handlePeerArrow(evt.currentTarget.id);
                                                    peerArrowSelected(evt.currentTarget.id);
                                                  }}
                                                />
                                              ) : peersLists.some(
                                                  (item) =>
                                                    item.isClicked &&
                                                    item.syncNumber == sync.SyncListNumber &&
                                                    item.peerData.length != 0
                                                ) ? (
                                                <RiIcons.RiArrowDownSFill
                                                  className='nav-arrow'
                                                  id={sync.SyncListNumber}
                                                  onClick={(evt) => {
                                                    handlePeerArrow(evt.currentTarget.id);
                                                    setPeersArrow({
                                                      item: -1,
                                                      index: -1,
                                                    });
                                                    // setSyncSelected();
                                                  }}
                                                />
                                              ) : null}
                                            </div>
                                            <li>
                                              {peersArrow.item == sync.SyncListNumber
                                                ? peersLists[syncArrow.index].peerData.map((peer) => {
                                                    return (
                                                      <div className='nav-Link'>
                                                        <NavLink
                                                          className={`nav-link peer-sub ${
                                                            peer.SIDNumber == peerSelected ? "selected" : null
                                                          }`}
                                                          key={peer.SIDNumber}
                                                          id={[peersLists[syncArrow.index].bridge, peer.SIDNumber]}
                                                          to='/peerLedgerDashboard'
                                                          onClick={(evt) => {
                                                            // setPeerSelected(
                                                            //   evt
                                                            //     .currentTarget
                                                            //     .id[1]
                                                            // );
                                                            handlePeerSelected([
                                                              evt.currentTarget.bridge,
                                                              evt.currentTarget.id,
                                                            ]);
                                                            setSyncSelected();
                                                            setBridgeSelected();
                                                            setGlobalSelected();
                                                          }}
                                                        >
                                                          {`Peer: ${peer.SIDNumber}`}
                                                        </NavLink>
                                                      </div>
                                                    );
                                                  })
                                                : null}
                                            </li>
                                          </div>
                                        );
                                      })
                                    : null}
                                </li>
                                <div className='nav-link'>
                                  <NavLink
                                    className={`nav-link sub-nav bridge-sub ${
                                      bridge.BlockNumber == bridgeSelected ? "selected" : null
                                    }`}
                                    key={bridge.BlockNumber}
                                    id={bridge.BlockNumber}
                                    to='/BridgeConsensusView'
                                    exact
                                    onClick={(evt) => {
                                      setBridgeSelected(evt.currentTarget.id);
                                      getSelectedBlock(evt.currentTarget.id);
                                      // setSelectedGlobal();
                                      setGlobalSelected(evt.currentTarget.id);
                                      setSyncSelected();
                                      setPeerSelected();
                                      handleBridgeSelected(evt.currentTarget.id);
                                    }}
                                  >{`Bridge ${bridge.BlockNumber} (${new Date(
                                    bridge.UnixTime * 1000
                                  ).toLocaleTimeString()})`}</NavLink>
                                  {/* ***************************
                                   ***** SYNC LIST ARROW *****
                                   *************************** */}
                                  {syncLists.some(
                                    (item) =>
                                      !item.isClicked && item.bridge == bridge.BlockNumber && item.syncData.length != 0
                                  ) ? (
                                    <RiIcons.RiArrowRightSFill
                                      id={bridge.BlockNumber}
                                      className='nav-arrow'
                                      onClick={(evt) => {
                                        // setBridgeSelected(evt.currentTarget.id);
                                        handleSyncArrow(evt.currentTarget.id);
                                        setPeersArrow({
                                          item: -1,
                                          index: -1,
                                        });
                                        syncArrowSelected(evt.currentTarget.id);
                                        //setSelectedGlobal(evt.currentTarget.id);
                                      }}
                                    />
                                  ) : syncLists.some(
                                      (item) =>
                                        item.isClicked && item.bridge == bridge.BlockNumber && item.syncData.length != 0
                                    ) ? (
                                    <RiIcons.RiArrowUpSFill
                                      className='nav-arrow'
                                      id={bridge.BlockNumber}
                                      onClick={(evt) => {
                                        handleSyncArrow(evt.currentTarget.id);
                                        handlePeerArrow(evt.currentTarget.id);
                                        setSyncArrow({ item: -1, index: -1 });
                                        setPeersArrow({
                                          item: -1,
                                          index: -1,
                                        });
                                        setSyncSelected();
                                      }}
                                    />
                                  ) : null}
                                </div>
                              </div>
                            );
                          })
                        : null}
                    </li>
                    {/* ***** GLOBAL LIST ITEM ***** */}
                    <li className='nav-link'>
                      <NavLink
                        className={`nav-link sub-nav ${global.BlockNumber == globalSelected ? "selected" : null}`}
                        key={global.BlockNumber}
                        id={global.BlockNumber}
                        to='/globalConsensusView_1'
                        exact
                        onClick={(evt) => {
                          handleSelected(evt.currentTarget.id);
                          getSelectedBlock(evt.currentTarget.id);
                          setGlobalSelected(evt.currentTarget.id);
                          setBridgeSelected();
                          setSyncSelected();
                          setPeerSelected();
                        }}
                      >
                        {`Global ${global.BlockNumber} (${new Date(global.UnixTime * 1000).toLocaleDateString()})`}
                      </NavLink>
                      {/* *************************
                       ***** BRIDGES ARROW *****
                       ************************* */}
                      {bridges.some(
                        (item) => !item.isClicked && item.global == global.BlockNumber && item.bridgeData.length != 0
                      ) ? (
                        <RiIcons.RiArrowRightSFill
                          id={global.BlockNumber}
                          className='nav-arrow'
                          onClick={(evt) => {
                            handleBridgeArrow(evt.currentTarget.id);
                            handleSyncArrow(evt.currentTarget.id);
                            handlePeerArrow(evt.currentTarget.id);
                            bridgeArrowSelected(evt.currentTarget.id);
                            setSelectedGlobal(evt.currentTarget.id);
                            setGlobalSelected(evt.currentTarget.id);
                            setPeersArrow({ item: -1, index: -1 });
                            setSyncArrow({ item: -1, index: -1 });
                            setBridgeSelected();
                          }}
                        />
                      ) : bridges.some(
                          (item) => item.isClicked && item.global == global.BlockNumber && item.bridgeData.length != 0
                        ) ? (
                        <RiIcons.RiArrowUpSFill
                          className='nav-arrow'
                          id={global.BlockNumber}
                          onClick={(evt) => {
                            handleBridgeArrow(evt.currentTarget.id);
                            handleSyncArrow(evt.currentTarget.id);
                            handlePeerArrow(evt.currentTarget.id);
                            setPeersArrow({ item: -1, index: -1 });
                            setSyncArrow({ item: -1, index: -1 });
                            setSelectedGlobal(evt.currentTarget.id);
                            setBridgeArrow({ item: -1, index: -1 });
                          }}
                        />
                      ) : null}
                    </li>
                  </div>
                );
              })
            : null
          : null}
        {/* ********************************************
         ***** SUBMIT TRANSACTION CONTRACT LINK *****
         ******************************************** */}
        <li>
          <NavLink
            className={`nav-link ${selectedGlobal == -5 ? "selected" : null}`}
            // onClick={() => updateTitle("Submit Transaction Contract")}
            id={-5}
            to='/submitTransactionContract'
            exact
            onClick={(evt) => {
              setSelectedGlobal(evt.currentTarget.id);
              handleBridgeArrow(evt.currentTarget.id);
              setBridgeArrow({ item: -1, index: -1 });
              setBridgeSelected();
              setGlobalSelected();
              setSyncSelected();
              setPeerSelected();
            }}
          >
            Submit Contract
          </NavLink>
        </li>
      </ul>
    </div>
  );
  //   <div>
  //     <ul>
  //
  //       {globals.isLoading != true
  //         ? consensusArrow
  //           ? globals.globalData.map((global) => {
  //               return (
  //                 <div>
  //                   <li className='nav-link'>
  //                     <NavLink
  //                       className={`nav-link sub-nav ${global.BlockNumber == globalSelected ? "selected" : null}`}
  //                       to='/globalConsensusView_1'
  //                       key={global.BlockNumber}
  //                       id={global.BlockNumber}
  //                       onClick={(evt) => {
  //                         handleSelected(evt.currentTarget.id);
  //                         getSelectedBlock(evt.currentTarget.id);
  //                         setGlobalSelected(evt.currentTarget.id);
  //                         setBridgeSelected();
  //                       }}
  //                       exact
  //                     >
  //                       {`Global ${global.BlockNumber} (${new Date(global.UnixTime * 1000).toLocaleDateString()})`}
  //                     </NavLink>
  //                     {/* ***** BRIDGES ARROW ***** */}
  //                     {bridges.some(
  //                       (item) => !item.isClicked && item.global == global.BlockNumber && item.bridgeData.length != 0
  //                     ) ? (
  //                       <RiIcons.RiArrowRightSFill
  //                         id={global.BlockNumber}
  //                         className='nav-arrow'
  //                         onClick={(evt) => {
  //                           handleBridgeArrow(evt.currentTarget.id);
  //                           handleSyncArrow(evt.currentTarget.id);
  //                           handlePeerArrow(evt.currentTarget.id);
  //                           bridgeArrowSelected(evt.currentTarget.id);
  //                           setSelectedGlobal(evt.currentTarget.id);
  //                           setGlobalSelected(evt.currentTarget.id);
  //                           setPeersArrow({ item: -1, index: -1 });
  //                           setSyncArrow({ item: -1, index: -1 });
  //                           setBridgeSelected();
  //                         }}
  //                       />
  //                     ) : bridges.some(
  //                         (item) => item.isClicked && item.global == global.BlockNumber && item.bridgeData.length != 0
  //                       ) ? (
  //                       <RiIcons.RiArrowDownSFill
  //                         className='nav-arrow'
  //                         id={global.BlockNumber}
  //                         onClick={(evt) => {
  //                           handleBridgeArrow(evt.currentTarget.id);
  //                           handleSyncArrow(evt.currentTarget.id);
  //                           handlePeerArrow(evt.currentTarget.id);
  //                           setPeersArrow({ item: -1, index: -1 });
  //                           setSyncArrow({ item: -1, index: -1 });
  //                           setSelectedGlobal(evt.currentTarget.id);
  //                           setBridgeArrow({ item: -1, index: -1 });
  //                         }}
  //                       />
  //                     ) : null}
  //                   </li>
  //                   <li>
  //                     {/* ***** BRIDGE ITEM ***** */}
  //                     {bridgeArrow.item == global.BlockNumber
  //                       ? bridges[bridgeArrow.index].bridgeData.map((bridge) => {
  //                           return (
  //                             <div>
  //                               <div className='nav-link'>
  //                                 <NavLink
  //                                   className={`nav-link sub-nav bridge-sub ${
  //                                     bridge.BlockNumber == bridgeSelected ? "selected" : null
  //                                   }`}
  //                                   key={bridge.BlockNumber}
  //                                   id={bridge.BlockNumber}
  //                                   to='/BridgeConsensusView'
  //                                   onClick={(evt) => {
  //                                     setBridgeSelected(evt.currentTarget.id);
  //                                     getSelectedBlock(evt.currentTarget.id);
  //                                     // setSelectedGlobal();
  //                                     setGlobalSelected(evt.currentTarget.id);
  //                                     setSyncSelected();
  //                                     setPeerSelected();
  //                                     handleBridgeSelected(evt.currentTarget.id);
  //                                   }}
  //                                 >{`Bridge ${bridge.BlockNumber} (${new Date(
  //                                   bridge.UnixTime * 1000
  //                                 ).toLocaleTimeString()})`}</NavLink>
  //                                 {/* ***** SYNC LIST ARROW ***** */}
  //                                 {syncLists.some(
  //                                   (item) =>
  //                                     !item.isClicked && item.bridge == bridge.BlockNumber && item.syncData.length != 0
  //                                 ) ? (
  //                                   <RiIcons.RiArrowRightSFill
  //                                     id={bridge.BlockNumber}
  //                                     className='nav-arrow'
  //                                     onClick={(evt) => {
  //                                       // setBridgeSelected(evt.currentTarget.id);
  //                                       handleSyncArrow(evt.currentTarget.id);
  //                                       setPeersArrow({
  //                                         item: -1,
  //                                         index: -1,
  //                                       });
  //                                       syncArrowSelected(evt.currentTarget.id);
  //                                       //setSelectedGlobal(evt.currentTarget.id);
  //                                     }}
  //                                   />
  //                                 ) : syncLists.some(
  //                                     (item) =>
  //                                       item.isClicked && item.bridge == bridge.BlockNumber && item.syncData.length != 0
  //                                   ) ? (
  //                                   <RiIcons.RiArrowDownSFill
  //                                     className='nav-arrow'
  //                                     id={bridge.BlockNumber}
  //                                     onClick={(evt) => {
  //                                       handleSyncArrow(evt.currentTarget.id);
  //                                       handlePeerArrow(evt.currentTarget.id);
  //                                       setSyncArrow({ item: -1, index: -1 });
  //                                       setPeersArrow({
  //                                         item: -1,
  //                                         index: -1,
  //                                       });
  //                                       setSyncSelected();
  //                                     }}
  //                                   />
  //                                 ) : null}
  //                               </div>
  //                               <ul>
  //                                 <li>
  //                                   {/* ***** SYNC LIST ITEM ***** */}
  //                                   {syncArrow.item == bridge.BlockNumber
  //                                     ? syncLists[syncArrow.index].syncData.map((sync) => {
  //                                         return (
  //                                           <div>
  //                                             <div className='nav-link'>
  //                                               <NavLink
  //                                                 className={`nav-link sync-sub ${
  //                                                   sync.SyncListNumber == syncSelected ? "selected" : null
  //                                                 }`}
  //                                                 key={sync.SyncListNumber}
  //                                                 id={sync.SyncListNumber}
  //                                                 to='/syncListView'
  //                                                 onClick={(evt) => {
  //                                                   handleSyncSelected(evt.currentTarget.id);
  //                                                   getSelectedBlock(evt.currentTarget.id);
  //                                                   setBridgeSelected();
  //                                                   setGlobalSelected();
  //                                                   setPeerSelected();
  //                                                 }}
  //                                               >{`Sync: ${sync.SyncListNumber}`}</NavLink>
  //                                               {/* ***** PEER LIST ARROWS ***** */}

  //                                               {peersLists.some(
  //                                                 (item) =>
  //                                                   !item.isClicked &&
  //                                                   item.syncNumber == sync.SyncListNumber &&
  //                                                   item.peerData.length != 0
  //                                               ) ? (
  //                                                 <RiIcons.RiArrowRightSFill
  //                                                   id={sync.SyncListNumber}
  //                                                   className='nav-arrow'
  //                                                   onClick={(evt) => {
  //                                                     handlePeerArrow(evt.currentTarget.id);
  //                                                     peerArrowSelected(evt.currentTarget.id);
  //                                                   }}
  //                                                 />
  //                                               ) : peersLists.some(
  //                                                   (item) =>
  //                                                     item.isClicked &&
  //                                                     item.syncNumber == sync.SyncListNumber &&
  //                                                     item.peerData.length != 0
  //                                                 ) ? (
  //                                                 <RiIcons.RiArrowDownSFill
  //                                                   className='nav-arrow'
  //                                                   id={sync.SyncListNumber}
  //                                                   onClick={(evt) => {
  //                                                     handlePeerArrow(evt.currentTarget.id);
  //                                                     setPeersArrow({
  //                                                       item: -1,
  //                                                       index: -1,
  //                                                     });
  //                                                     // setSyncSelected();
  //                                                   }}
  //                                                 />
  //                                               ) : null}
  //                                             </div>
  //                                             {/* ***** PEER LIST ITEM ***** */}
  //                                             <ul>
  //                                               <li>
  //                                                 {peersArrow.item == sync.SyncListNumber
  //                                                   ? peersLists[syncArrow.index].peerData.map((peer) => {
  //                                                       return (
  //                                                         <div className='nav-Link'>
  //                                                           <NavLink
  //                                                             className={`nav-link peer-sub ${
  //                                                               peer.SIDNumber == peerSelected ? "selected" : null
  //                                                             }`}
  //                                                             key={peer.SIDNumber}
  //                                                             id={[peersLists[syncArrow.index].bridge, peer.SIDNumber]}
  //                                                             to='/peerLedgerDashboard'
  //                                                             onClick={(evt) => {
  //                                                               // setPeerSelected(
  //                                                               //   evt
  //                                                               //     .currentTarget
  //                                                               //     .id[1]
  //                                                               // );
  //                                                               handlePeerSelected([
  //                                                                 evt.currentTarget.bridge,
  //                                                                 evt.currentTarget.id,
  //                                                               ]);
  //                                                               setSyncSelected();
  //                                                               setBridgeSelected();
  //                                                               setGlobalSelected();
  //                                                             }}
  //                                                           >
  //                                                             {`Peer: ${peer.SIDNumber}`}
  //                                                           </NavLink>
  //                                                         </div>
  //                                                       );
  //                                                     })
  //                                                   : null}
  //                                               </li>
  //                                             </ul>
  //                                           </div>
  //                                         );
  //                                       })
  //                                     : null}
  //                                 </li>
  //                               </ul>
  //                             </div>
  //                           );
  //                         })
  //                       : null}
  //                   </li>
  //                 </div>
  //               );
  //             })
  //           : null
  //         : null}
  //       {/* <li>
  //         <NavLink
  //           className={`nav-link ${selectedGlobal == -3 ? "selected" : null}`}
  //           //onClick={() => updateTitle("Peer Ledger Dashboard")}
  //           to='/peerLedgerDashboard'
  //           id={-3}
  //           onClick={(evt) => {
  //             setSelectedGlobal(evt.currentTarget.id);
  //             handleBridgeArrow(evt.currentTarget.id);
  //             setBridgeArrow({ item: -1, index: -1 });
  //             setBridgeSelected();
  //           }}
  //         >
  //           Peer Ledger
  //         </NavLink>
  //       </li> */}
  //       <li>
  //         <NavLink
  //           className={`nav-link ${selectedGlobal == -5 ? "selected" : null}`}
  //           // onClick={() => updateTitle("Submit Transaction Contract")}
  //           id={-5}
  //           to='/submitTransactionContract'
  //           onClick={(evt) => {
  //             setSelectedGlobal(evt.currentTarget.id);
  //             handleBridgeArrow(evt.currentTarget.id);
  //             setBridgeArrow({ item: -1, index: -1 });
  //             setBridgeSelected();
  //           }}
  //         >
  //           Submit Contract
  //         </NavLink>
  //       </li>
  //     </ul>
  //   </div>
  // );
};

export default withRouter(Navigation);
