import { timeYear, timeYears } from "d3";
import React from "react";

import "./Footer.Styles.scss";

let today = new Date();

const Footer = () => {
    return (
        <div className='footer'>
            <div className='footer-container'>
                <p>
                    &copy; Copyright {today.getFullYear()} Blockframe&reg; Inc.
                    All rights reserved.
                </p>
            </div>
        </div>
    );
};

export default Footer;
