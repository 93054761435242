import React, { useState, useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";

import PeerLedgerBar from "../../components/charts/peerLedger/PeerLedgerBar";

import { useFetchContent } from "../../contexts/Content.context.js";
import { PrimaryContext } from "../../contexts/Primary.context";

import "./PeerLedgerDBView.styles.scss";

import PLDB from "../../data/peerLedgerDB.data";

const PeerLedgerDBView = ({ slider }) => {
  const { primaryList } = useContext(PrimaryContext);
  const { fetchContent, contentList } = useFetchContent();
  useEffect(() => {
    fetchContent(primaryList[0].bridge);
  }, [primaryList]);
  console.log(contentList);
  return (
    <div className='ledger-view'>
      <div className='card-row'>
        <div className='card card-15'>
          <h3>Phyli Balance</h3>
          <h2>18000</h2>
        </div>
        <div className='card card-15'>
          <h3>Phyli Hold</h3>
          <h2>475</h2>
        </div>
        <div className='card card-15'>
          <h3>Stamp Balance</h3>
          <h2>27</h2>
        </div>
        <div className='card card-15'>
          <h3>License Type</h3>
          <h2>Legal</h2>
        </div>
        <div className='card card-15'>
          <h3>Transaction Contract Number</h3>
          <h2>2251</h2>
        </div>
        <div className='card card-15'>
          <h3>SyncList Number</h3>
          <h2>2319</h2>
        </div>
      </div>
      <div className='card-row'>
        <div className='card card-70'>
          <h2>Viewing Ledger - 871</h2>
          <button className='ledger-button'>273</button>
          <button className='ledger-button'>1519</button>
          <button className='ledger-button selected'>871</button>
        </div>
      </div>
      <div className='chart-row'>
        <div className='ledger-chart-left'>
          <div>
            <PeerLedgerBar />
          </div>
        </div>
        <div className='ledger-chart-right'>
          <div className='primary-consensus'>
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Block Count</th>
                </tr>
              </thead>
              <tbody>
                {primaryList.map((primary) =>
                  !primary.primaryData.length == 0 ? (
                    primary.primaryData.map((item) => (
                      <tr>
                        <td>{new Date(item.UnixTime * 1000).toLocaleDateString()}</td>
                        <td>{new Date(item.UnixTime * 1000).toLocaleTimeString()}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>NO PRIMARY</td>
                    </tr>
                  )
                )}
                {/* {PLDB.times.map((time) => {
                  return (
                    <tr>
                      <td>10/04/2021</td>
                      <td>{time}</td>
                    </tr>
                  );
                })} */}
              </tbody>
            </table>
            <table>
              <thead>
                <tr>
                  <th>Block ID</th>
                  <th>Time Stamp</th>
                </tr>
              </thead>
              <tbody>
                {contentList[0].contentData != null
                  ? contentList[0].contentData.map((item) => {
                      return (
                        <tr>
                          <td className='content-id' onClick={() => slider.setSlider(!slider.sliderButton)}>
                            {item.BlockNumber}
                          </td>
                          <td>{new Date(item.UnixTime).toLocaleTimeString()}</td>
                        </tr>
                      );
                    })
                  : console.log("no Data")}
                {/* {this.props.content.map((item, index) => {
                     if (index < this.state.count && index < 84) {
                       return (
                         <tr>                           <td>{item.BlockNumber}</td>
                           <td className="time-stamp">
                             {new Date(item.UnixTime * 1000).toLocaleString()}
                           </td>
                         </tr>
                       );
                     }
                   })} */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

//               <table>
//                 <thead>
//                   <tr>
//                     <th>Date</th>
//                     <th>Time</th>
//                     <th>Block Count</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {PLDB.times.map((time) => {
//                     return (
//                       <tr>
//                         <td>05/28/2021</td>
//                         <td>{time}</td>
//                       </tr>
//                     );
//                   })}
//                 </tbody>
//               </table>
//               <table>
//                 <thead>
//                   <tr>
//                     <th>Block ID</th>
//                     <th>Time Stamp</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {/* {this.props.content.map((item, index) => {
//                     if (index < this.state.count && index < 84) {
//                       return (
//                         <tr>
//                           <td>{item.BlockNumber}</td>
//                           <td className="time-stamp">
//                             {new Date(item.UnixTime * 1000).toLocaleString()}
//                           </td>
//                         </tr>
//                       );
//                     }
//                   })} */}
//                 </tbody>
//               </table>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }

//   componentDidMount() {
//     this.myInterval = setInterval(() => {
//       this.setState((prevState) => ({
//         count: prevState.count + 1,
//       }));
//     }, 20000);
//   }

//   componentWillUnmount() {
//     clearInterval(this.myInterval);
//   }
// }

export default PeerLedgerDBView;
