import React, { useContext, useState, useEffect } from "react";

// Component imports
import SmallCard from "../../components/smallCard/SmallCard.component";
import BridgeConsensusLine from "../../components/charts/bridgeConsensus/BridgeConsensusLine";
import BridgeConsensusPie from "../../components/charts/bridgeConsensus/BridgeConsensusPie";

// Context imports
import { SelectedBlockContext } from "../../contexts/SelectedBlock.context";
import { GlobalsContext } from "../../contexts/globals.context";
import { BridgesContext } from "../../contexts/Bridges.context";

// Style imports
import "./BridgeConsensuView_1.styles.scss";

// Temporary Imports
import GLOBAL_STATS from "../../data/globalStats.data";

const BridgeConsensus_1 = ({ treeData }) => {
  let currentBridge = { global: 0, bridge: {} };
  const [currentDeserialized, setCurrentDeserialized] = useState();
  const bridges = useContext(BridgesContext);
  const selectedBlock = useContext(SelectedBlockContext);
  const [currentBridgeInfo, setCurrentBridgeInfo] = useState({
    peerCount: 0,
    phyliTransferred: 0,
    globalHoldMargin: 0,
    bridgeHoldMargin: 0,
    cumulativeBridgeTrust: 0,
    globalPLPercentage: 0,
  });

  bridges.bridges.map((bridge) => {
    bridge.bridgeData.map((bridgeItem) => {
      if (bridgeItem.BlockNumber == selectedBlock.selectedBlock.BlockNumber) {
        currentBridge = { global: bridge.global, bridge: bridgeItem };
      }
    });
  });

  const fetchDeserialized = async (global, blockNumber) => {
    if (blockNumber != undefined) {
      const setGlobal = await fetch(`https://api.philos.us:42074/global/${global}`);
      const setBridge = await fetch(`https://api.philos.us:42074/bridge/${blockNumber}`);
      const bridgeRes = await fetch("https://api.philos.us:42074/bridge/deserialized");
      const bridgeDeserialized = await bridgeRes.json();

      if (bridgeDeserialized != undefined) {
        let peerCount = 0;
        let trustTotal = 0;
        let phyliTransferred = 0;
        let totalBalance = 0;
        let totalHold = 0;
        let globalData = {};
        GLOBAL_STATS.map((item) => {
          if (item.BlockNumber == currentBridge.global) {
            globalData = item;
          }
        });
        if (bridgeDeserialized.BlockType == 5) {
          bridgeDeserialized.PeerStates.map((peer) => {
            peerCount += 1;
            trustTotal += peer.SyncTrust + 1;
            phyliTransferred += peer.Stamps;
            totalBalance += peer.Balance;
            totalHold += peer.Hold;
          });

          setCurrentBridgeInfo({
            ...currentBridgeInfo,
            peerCount: peerCount,
            phyliTransferred: phyliTransferred,
            globalHoldMargin: ((totalHold / globalData.GlobalPhyliBal) * 100).toFixed(2),
            bridgeHoldMargin: ((totalHold / totalBalance) * 100).toFixed(2),
            cumulativeBridgeTrust: trustTotal,
            globalPLPercentage: ((peerCount / 6) * 100).toFixed(2),
          });
        } else if (bridgeDeserialized.BlockType == 6) {
          bridgeDeserialized.Payload.FinalPeerStates[0].map((peer) => {
            peerCount += 1;
            trustTotal += peer.SyncTrust + 2;
            phyliTransferred += peer.Stamps;
            totalBalance += peer.Balance;
            totalHold += peer.Hold;
          });

          setCurrentBridgeInfo({
            ...currentBridgeInfo,
            peerCount: peerCount,
            phyliTransferred: phyliTransferred,
            globalHoldMargin: ((totalHold / globalData.GlobalPhyliBal) * 100).toFixed(2),
            bridgeHoldMargin: ((totalHold / totalBalance) * 100).toFixed(2),
            cumulativeBridgeTrust: trustTotal,
            globalPLPercentage: ((peerCount / 6) * 100).toFixed(2),
          });
        } else if (bridgeDeserialized.BlockType == 7) {
          bridgeDeserialized.BridgeState.DivisionStates[0].ActivePeerStates.map((peer) => {
            peerCount += 1;
            trustTotal += peer.SyncTrust + 3;
            phyliTransferred += peer.Stamps;
            totalBalance += peer.Balance;
            totalHold += peer.Hold;
          });

          setCurrentBridgeInfo({
            ...currentBridgeInfo,
            peerCount: peerCount,
            phyliTransferred: phyliTransferred,
            globalHoldMargin: ((totalHold / globalData.GlobalPhyliBal) * 100).toFixed(2),
            bridgeHoldMargin: ((totalHold / totalBalance) * 100).toFixed(2),
            cumulativeBridgeTrust: trustTotal,
            globalPLPercentage: ((peerCount / 6) * 100).toFixed(2),
          });
        }
      }
    }
    return;
  };

  try {
    // ***** FETCH DESERIALIZED BRIDGE USING FETCH FUNCTION ****
    useEffect(() => {
      fetchDeserialized(currentBridge.global, currentBridge.bridge.BlockNumber);
      // setDeserialized(currentDeserialized);
    }, [currentBridge.bridge.BlockNumber]);
  } catch (error) {}
  return (
    <div className='bridge-view '>
      <div className='card-row'>
        <SmallCard
          className='card card-15'
          title='Total Phyli Transferred'
          content={currentBridgeInfo.phyliTransferred}
        />
        <SmallCard
          className='card card-15'
          title='Global Hold Margin'
          content={`${currentBridgeInfo.globalHoldMargin}%`}
        />
        <SmallCard
          className='card card-15'
          title='Bridge Hold Margin'
          content={`${currentBridgeInfo.bridgeHoldMargin}%`}
        />
        <SmallCard
          className='card card-15'
          title='Cumulative Bridge Trust'
          content={currentBridgeInfo.cumulativeBridgeTrust}
        />
        <SmallCard className='card card-15' title='Number of SL/Ledgers' content={`${currentBridgeInfo.peerCount}/6`} />
        <SmallCard
          className='card card-15'
          title='Global PL Percentage'
          content={`${currentBridgeInfo.globalPLPercentage}%`}
        />
      </div>
      <div className='bridge-chart-container'>
        <div className='bridge-line'>
          <BridgeConsensusLine treeData={treeData} />
        </div>
        <div className='bridge-pie'>
          <h1>Bridge Trust Distribution</h1>
          <BridgeConsensusPie />
        </div>
      </div>
    </div>
  );
};

export default BridgeConsensus_1;
