import React, { useState, useEffect, useContext } from "react";

import SmallCard from "../../components/smallCard/SmallCard.component";
import ChartRow from "../../components/chartRow/ChartRow.component";
import GlobalLineChart from "../../components/charts/globalConsensus/GlobalLineChart";
import GlobalPieChart from "../../components/charts/globalConsensus/GlobalPieChart";
import SliderContainer from "../../components/slider/Slider.component";

import GLOBAL_STATS from "../../data/globalStats.data";
import { SelectedBlockContext } from "../../contexts/SelectedBlock.context";
import { GlobalsContext } from "../../contexts/globals.context";

import "./GlobalConsensusView_1.styles.scss";
import { curveStepAfter } from "d3-shape";

const GlobalConsensus_1 = ({ data, globalData }) => {
  const blockSelected = useContext(SelectedBlockContext);
  const globals = useContext(GlobalsContext);
  const [globalIndex, setGlobalIndex] = useState(-2);

  useEffect(() => {
    GLOBAL_STATS.map((item, index) => {
      if (item.BlockNumber == blockSelected.selectedBlock.BlockNumber) {
        setGlobalIndex(index);
        return;
      }
    });
  });
  {
    if (globals.isLoading) {
      return (
        <div>
          <h1>Globals loading...</h1>
        </div>
      );
    } else if (globals.globalData == []) {
      return (
        <div>
          <h1>Globals failed to load please refresh..</h1>
        </div>
      );
    }
  }
  return (
    <div className='global-consensus-view'>
      <div className='card-row'>
        <SmallCard
          className='card'
          title='Global Phyli Balance'
          content={
            globalIndex != -2
              ? GLOBAL_STATS[globalIndex].GlobalPhyliBal
              : "No Data"
          }
        />
        <SmallCard
          className='card'
          title='Global Phyli Holds'
          content={
            globalIndex != -2
              ? GLOBAL_STATS[globalIndex].GlobalPhyliHold
              : "No Data"
          }
        />
        <SmallCard
          className='card'
          title='Total Blocks Created'
          content={
            globalIndex != -2
              ? GLOBAL_STATS[globalIndex].BlocksAdded
              : "No Data"
          }
        />
        <SmallCard
          className='card'
          title='Peers Under Consensus'
          content={
            globalIndex != -2
              ? GLOBAL_STATS[globalIndex].PeersUnderConsensus
              : "No Data"
          }
        />
      </div>
      <ChartRow
        chartLine={
          <GlobalLineChart
            globalData={data.globalsData}
            globalsData={globalData}
          />
        }
        chartPie={
          <GlobalPieChart
            phyliBal={
              globalIndex != -2
                ? GLOBAL_STATS[globalIndex].GlobalPhyliBal
                : "No Data"
            }
            phyliHold={
              globalIndex != -2
                ? GLOBAL_STATS[globalIndex].GlobalPhyliHold
                : "No Data"
            }
          />
        }
      />
    </div>
  );
};

export default GlobalConsensus_1;
