import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import '../sideNav/Navigation.styles.scss'

const SidebarLink = NavLink;

const BridgeMenu = ({item, index, updateTitle}) => {
    const [bridgeNav, setBridgeNav] = useState(false);

    const showBridgeNav = () => setBridgeNav(!bridgeNav);

    return (
        <div>
            <SidebarLink className="nav-link" onClick={item.bridges && showBridgeNav} to="#">
                <ul>
                    <li>
                        {`global ${item.globalBlock}`}
                    </li>
                    <ul>
                    {
                        bridgeNav && item.bridges.map((item, index) => {
                            return (
                                <li className="nav-link">
                                    <NavLink className="nav-link bridge-link" onClick={() => updateTitle("Bridge View")} to="/bridgeConsensusView">
                                        {`Bridge: ${item}`}
                                    </NavLink>  
                                </li>
                            )
                        })
                    }   
                    
                    </ul>
                </ul>
            </SidebarLink>
            
        </div>
    )
}

export default BridgeMenu;