const PARAMETERS = {
    sid: 159,
    aid: 4,
    adminAct: 7,
    cumulativeValue: 2.1,
    waiting: 2,
    lastConsensus: 94,
    primarySync: 300,
    maxInterval: 28800,
    minSyncSize: 3,
    missedSyncTime: 24,
    inactiveTime: 30,
    creditPrice: 54.01,
    pieData: [128, 2, 29]
}

export default PARAMETERS;