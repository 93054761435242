import React, { createContext, useContext, useState } from "react";

import { BridgesContext } from "./Bridges.context";

export const SyncListContext = createContext();

const SyncListContextProvider = (props) => {
  const [syncLists, setSyncLists] = useState([
    {
      isLoading: true,
      isError: false,
      isClicked: false,
      bridge: 0,
      syncData: [],
    },
  ]);

  const [peersLists, setPeersLists] = useState([
    {
      isLoading: true,
      isError: false,
      isClicked: false,
      syncNumber: 0,
      bridge: 0,
      peerData: [],
    },
  ]);

  return (
    <SyncListContext.Provider
      value={{ syncLists, setSyncLists, peersLists, setPeersLists }}
    >
      {props.children}
    </SyncListContext.Provider>
  );
};

export const useFetchSyncList = () => {
  const { syncLists, setSyncLists } = useContext(SyncListContext);
  const { peersLists, setPeersLists } = useContext(SyncListContext);
  const { bridges } = useContext(BridgesContext);
  let newIndex = -1;

  const fetchSync = async (blockNumber) => {
    bridges.map((bridge, index) => {
      bridge.bridgeData.map((item) => {
        if (item.BlockNumber == blockNumber) {
          newIndex = index;
        }
      });
    });
    try {
      const setGlobal = await fetch(
        `https://api.philos.us:42074/global/${bridges[newIndex].global}`
      );
      const setBridge = await fetch(
        `https://api.philos.us:42074/bridge/${blockNumber}`
      );
      const resSync = await fetch(`https://api.philos.us:42074/psl/`);
      const syncData = await resSync.json();
      if (syncLists.isLoading) {
        console.log("Loading sync list");
      }
      if (syncData != "{Error: PNF}") {
        setSyncLists([
          ...syncLists,
          {
            isLoading: false,
            isError: false,
            isClicked: false,
            bridge: blockNumber,
            syncData: syncData,
          },
        ]);

        syncData.map((item) =>
          setPeersLists([
            ...peersLists,
            {
              isLoading: false,
              isError: false,
              isClicked: false,
              bridge: blockNumber,
              syncNumber: item.SyncListNumber,
              peerData: item.Peer,
            },
          ])
        );
      } else {
        setSyncLists([
          ...syncLists,
          {
            isLoading: false,
            isError: false,
            isClicked: false,
            bridge: 0,
            syncData: [],
          },
        ]);

        setPeersLists([
          ...peersLists,
          {
            isLoading: false,
            isError: false,
            isClicked: false,
            bridge: 0,
            syncNumber: 0,
            peerData: [],
          },
        ]);
      }
    } catch (error) {
      console.log(error);
      setSyncLists([
        {
          isLoading: false,
          isError: error,
          isClicked: false,
          bridge: 0,
          syncData: [],
        },
      ]);

      setPeersLists([
        ...peersLists,
        {
          isLoading: false,
          isError: false,
          isClicked: false,
          bridge: 0,
          syncNumber: 0,
          peerData: [],
        },
      ]);
    }
  };

  return {
    syncLists: syncLists,
    fetchSync: fetchSync,
    setSyncLists: setSyncLists,
    peersLists: peersLists,
    setPeersLists: setPeersLists,
  };
};

export default SyncListContextProvider;
