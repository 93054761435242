import React from "react";
import { Link, useLocation } from "react-router-dom";

import "./Header.styles.scss";

const Header = () => {
  const location = useLocation();
  let title = "";

  if (location.pathname === "/") {
    title = "Configuration Parameters";
  } else if (location.pathname === "/globalConsensusView_1") {
    title = "Global Consensus View";
  } else if (location.pathname === "/BridgeConsensusView") {
    title = "Bridge Consensus View";
  } else if (location.pathname === "/syncListView") {
    title = "Sync List View";
  } else if (location.pathname === "/peerLedgerDashboard") {
    title = "Peer Ledger View";
  }
  console.log(location.pathname);

  return (
    <div className='header'>
      <Link className='logo-container' to='/'>
        <h3 className='logo'>
          Philos<sup>TM</sup> Dashboard
        </h3>
      </Link>
      <div className='title'>
        <h5>{title}</h5>
      </div>
      <div className='options'>
        <Link className='option' to='#'>
          LOGIN
        </Link>
      </div>
    </div>
  );
};

export default Header;
