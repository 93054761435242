import React, { useState, useEffect } from "react";

import "./GlobalParametersView_1.styles.scss";

import SmallCard from "../../components/smallCard/SmallCard.component";
import ParametersPie from "../../components/charts/GlobalParameters/ParametersPieChart";

const GlobalParametersView_1 = ({ data, callback }) => {
  const [globalParam, setGlobalParam] = useState({
    isLoading: true,
    isError: false,
    configData: {},
  });

  const fetchParamData = async () => {
    try {
      if (globalParam.isLoading) {
        console.log("Loading Parameters");
      }
      const res = await fetch("https://api.philos.us:42074/config/GlobalParameters");
      const resData = await res.json();
      if (resData != undefined) {
        setGlobalParam({
          isLoading: false,
          isError: false,
          configData: resData,
        });
      }
    } catch (error) {
      setGlobalParam({
        isLoading: false,
        isError: true,
        configGlobalData: null,
      });
    }
  };

  useEffect(() => {
    fetchParamData();
  }, []);

  if (globalParam.isLoading) {
    return (
      <div>
        <h1>Data is loading...</h1>
      </div>
    );
  }

  const paramData = globalParam.configData[0];

  return (
    <div className='global-param-view'>
      <div className='card-row'>
        <SmallCard className='card card-15' title='Total SID Count' content={paramData.TotalSIDCount} />
        <SmallCard className='card card-15' title='Total AID Count' content={paramData.TotalAIDCount} />
        <SmallCard className='card card-15' title='Admin Action' content={paramData.OpenAdminActionCount} />
        <SmallCard
          className='card card-15'
          title='Cumulative Phyli Value'
          content={`${paramData.CumulativePhyliValue / 1000000} Mil`}
        />
        <SmallCard className='card card-15' title='Waiting Sync List Paring' content={paramData.PeersWaitingSyncList} />
        <SmallCard
          className='card card-15'
          title='Last Global Consensus'
          content={`${paramData.LastGlobalConsensusPercent * 100}%`}
        />
      </div>
      <div className='data-container'>
        <div className='data-list'>
          <h2>Primary Sync Time: {paramData.PrimarySyncInterval}</h2>
          <h2>Max Bridge Interval : {paramData.MaxBridgeInterval}</h2>
          <h2>Min Sync List Size: {paramData.MinimumPrimarySyncSize}</h2>
          <h2>Missed Sync Inactive Time: {`${paramData.MissedSyncInactiveInterval}`}</h2>
          <h2>Inactive Disenrollment: {`${paramData.InactiveDisenrollment}`}</h2>
          <h2>Current Phyli Price: {paramData.CurrentPhyliPrice} </h2>
        </div>
        <div className='pie-chart'>
          <h1>SID Status</h1>
          <ParametersPie pieData={paramData} />
        </div>
      </div>
    </div>
  );
};

export default GlobalParametersView_1;

// class GlobalParameterView_1 extends React.Component {
//     constructor(props) {
//         super(props);

//         this.state = {
//             globals: [],
//             isLoaded: false,
//         }

//     }
//     componentDidMount() {
//         const apiUrl = '/globals';
//         fetch(apiUrl)
//             .then((res) => res.json())
//             .then((data) => {
//                 this.setState({
//                     isLoaded: true,
//                     globals: data,
//                 })

//             })
//     }

//     render() {
//         var { isLoaded, globals } = this.state;

//         if (!isLoaded) {
//             return (
//                 <div>Loading...</div>
//             )
//         } else {
//             return (
//                 <div className='global-param-view'>
//                     <div className='card-row'>
//                         <SmallCard className="card card-15" title="Total SID Count" content="159"/>
//                         <SmallCard className="card card-15" title="Total DID Count" content="147"/>
//                         <SmallCard className="card card-15" title="Admin Action" content="441"/>
//                         <SmallCard className="card card-15" title="Cumulative Credit Value" content="$89,234"/>
//                         <SmallCard className="card card-15" title="Waiting Sync List Paring" content="5"/>
//                         <SmallCard className="card card-15" title="Last Global Consensus" content="89%"/>
//                     </div>
//                     <div className='data-container'>
//                         <div className='data-list'>
//                             <h2>Primary Sync Time: 600sec</h2>
//                             <h2>Max Bridge Interval : 28800sec</h2>
//                             <h2>Min Sync List Size: 3</h2>
//                             <h2>Missed Sync Inactive Time: 24hrs</h2>
//                             <h2>Inactive Disenrollment: 30days</h2>
//                             <h2>Current Credit Price: $54.01 per 3000</h2>
//                         </div>
//                         <div className='pie-chart'>
//                             <h1>SID Status</h1>
//                             <GlobalPie />
//                         </div>
//                     </div>
//                 </div>
//             )
//         }
//     }
// }

// export default GlobalParameterView_1;
