import React, { useContext, useState } from "react";

import "./PeerLedgerBCView.styles.scss";

import { SelectedBlockContext } from "../../contexts/SelectedBlock.context";
import { GlobalsContext } from "../../contexts/globals.context";

const PeerLedgerBCView = () => {
  const { selectedBlock } = useContext(SelectedBlockContext);
  const { selectedGlobal } = useContext(GlobalsContext);
  const [blockDeserialized, setBlockDeserialized] = useState([]);

  const fetchDeserialized = async (blocktype, blockNumber) => {
    const setGlobal = await fetch(
      `https://api.philos.us:42074/global/${selectedGlobal}`
    );
    const setBlock = await fetch(
      `https://api.philos.us:42074/${blocktype}/${blockNumber}`
    );
    const getDeserialized = await fetch(
      `https://api.philos.us:42074/${blocktype}/deserialized`
    );
    const deserializedBlock = await getDeserialized.json();
    setBlockDeserialized(deserializedBlock);
    const reset = await fetch(`https://api.philos.us:42074/last`);
  };

  const handleDeserialize = (block) => {
    if (block.BlockType == 9) {
      fetchDeserialized("global", block.BlockNumber);
    } else if (
      block.BlockType == 7 ||
      block.BlockType == 6 ||
      block.BlockType == 5
    ) {
      fetchDeserialized("bridge", block.BlockNumber);
    }
  };
  console.log(blockDeserialized);
  return (
    <div className='content-view'>
      <div className='card-row'>
        <div className='card'>
          <h3>Block Type</h3>
          <h3>{selectedBlock.BlockType}</h3>
        </div>
        <div className='card '>
          <h3>Block Time</h3>
          <h3>
            {new Date(selectedBlock.UnixTime * 1000).toLocaleTimeString()}
          </h3>
        </div>
        <div className='card'>
          <h3>Block Number</h3>
          <h3>{selectedBlock.BlockNumber}</h3>
        </div>
      </div>
      <div className='card-row'>
        <div className='card card-45'>
          <h3>Previous Hash</h3>
          <h4>
            {selectedBlock.PreviousHash != undefined
              ? atob(selectedBlock.PreviousHash)
              : "No Data"}
          </h4>
        </div>
        <div className='card card-45'>
          <h3>Block Hash</h3>
          <h4>
            {selectedBlock.Hash != undefined
              ? atob(selectedBlock.Hash)
              : "No Data"}
          </h4>
        </div>
      </div>
      <div className='text-row'>
        <div className='text-left'>
          <div className='text-area'>
            <h2>Serialized Data</h2>
            <p className='text-data text-wrap'>
              {selectedBlock.BlockContent != undefined
                ? selectedBlock.BlockContent
                : "No Data"}
            </p>
          </div>
        </div>
        <div className='text-middle'>
          <button
            className='component-button'
            onClick={() => handleDeserialize(selectedBlock)}
          >
            Deserialize
          </button>
          <button className='component-button'>Verify</button>
          <span>
            <button className='component-button search'>&#x3c;</button>
            <button className='component-button search'>&#x3e;</button>
          </span>
        </div>
        <div className='text-right'>
          <div className='text-area'>
            <h2>Deserialized Data</h2>
            {selectedBlock.BlockNumber == blockDeserialized.BlockNumber ? (
              <p>
                {blockDeserialized != undefined
                  ? JSON.stringify(blockDeserialized)
                  : "No Data"}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PeerLedgerBCView;
