import React, { useState, useEffect, useContext } from "react";
import { Route, Switch } from "react-router-dom";

// Styles
import "./App.scss";

// Hooks
import { useFetch } from "./hooks/useFetch.hook";

// Context
import { useFetchLast, useFetchGlobal } from "./contexts/globals.context";
import BridgesContextProvider from "./contexts/Bridges.context";
import SyncListContextProvider from "./contexts/SyncList.context";
import PeersContextProvider from "./contexts/Peers.context";
import ContentContextProvider from "./contexts/Content.context";
import PrimaryContextProvider from "./contexts/Primary.context";

// Workers
import CreateDataModel from "./workers/CreateDataModel";

// Pages
import GlobalParameterView_1 from "./pages/globalParametersView_1/GlobalParametersView_1.component";
import GlobalConsensusView1 from "./pages/globalConsensusView_1/GlobalConsensusView_1.component";
import BridgeConsensusView_1 from "./pages/bridgeConsensusView_1/BridgeConsensusView_1.component";
import PeerLedgerBCView from "./pages/peerLedgerBCView/PeerLedgerBCView.component";
import PeerLedgerDBView from "./pages/peerLedgerDBView/PeerLedgerDBView.component";
import syncListView from "./pages/syncListView_1/SyncListView_1";
import SubmitContract from "./pages/submitContract/SubmitContract.component";

// Components
import Header from "./components/header/Header.component";
import Navigation from "./components/sideNav/Navigation.component";
import Footer from "./components/footer/Footer.component";
import Slider from "./components/slider/Slider.component";

import PARAMETERS from "./data/parameters.data";
import CONSENSUS_DATA from "./data/consensus.data";
import TREEDATA from "./data/treedata.data";
import PLDB from "./data/peerLedgerDB.data";
import { Ri24HoursFill, Ri24HoursLine, RiAddFill, RiCreativeCommonsNcFill } from "react-icons/ri";

function App() {
  // ***** Temp Data Holders ****
  const [parameters, setParameters] = useState(PARAMETERS);
  const [consensusData, setConsensusData] = useState(CONSENSUS_DATA);
  const [treeData, setTreeData] = useState(TREEDATA);
  const [globalParameters, setGlobalParameters] = useState([]);
  const lastBlock = useFetchLast();
  const globals = useFetchGlobal();
  const [sliderButton, setSliderButton] = useState(true);

  // useEffect(() => {
  //     const fetchData = async () => {
  //         try {
  //             const res = await fetch(
  //                 "https://api.philos.us:42074/global/parameters"
  //             );
  //             const data = await res.json();
  //             setGlobalParameters(data);
  //         } catch (error) {
  //             console.log(error);
  //         }
  //     };
  //     fetchData();
  // }, []);

  if (lastBlock.isLoading && globals.isLoading) {
    return (
      <div>
        <h1>Data is loading</h1>
      </div>
    );
  }

  return (
    <div className='app-container'>
      <div className='grid-container header'>
        {/* <Header title={title} updateTitle={this.modifyTitle} /> */}
        <Header />
      </div>
      <div className='grid-container side-container'>
        <PeersContextProvider>
          <Navigation />
        </PeersContextProvider>

        {/* <Navigation
          treeData={dataModel}
          // updateTitle={this.modifyTitle}
          // dateClick={this.handleDateClick}
        /> */}
      </div>
      <div className='grid-container main-content'>
        <Slider slider={{ sliderButton: sliderButton, setSlider: setSliderButton }} />
        <div className='slider-button-div'>
          {sliderButton ? (
            <button className='slider-button' onClick={() => setSliderButton(!sliderButton)}>
              <RiAddFill />
            </button>
          ) : null}
        </div>

        <Switch>
          <Route path='/' render={() => <GlobalParameterView_1 data={parameters} />} exact />
          <Route
            path='/globalConsensusView_1'
            render={() => (
              <GlobalConsensusView1 treedata={treeData} data={consensusData} globalData={globals.globalData} />
            )}
            exact
          />
          <Route path='/bridgeConsensusView' render={() => <BridgeConsensusView_1 treeData={treeData} />} exact />
          <Route path='/peerLedgerBlockContent' exact>
            <PeerLedgerBCView />
          </Route>

          <Route path='/peerLedgerDashboard' exact>
            <ContentContextProvider>
              <PeerLedgerDBView slider={{ sliderButton: sliderButton, setSlider: setSliderButton }} />
            </ContentContextProvider>
          </Route>
          <Route path='/syncListView' component={syncListView} exact />
          <Route path='/submitTransactionContract' component={SubmitContract} exact />
          <Route render={() => <h1>404: page not found</h1>} />
        </Switch>
      </div>
      <div className='grid-container footer'>
        <Footer />
      </div>
    </div>
  );
}

export default App;
