import React from 'react';

import * as RiIcons from 'react-icons/ri';

export const SidenavData = [
    {
        title: "Configuration",
        path: "#",
        iconClosed: <RiIcons.RiArrowRightSFill />,
        iconOpened: <RiIcons.RiArrowDownSFill />,
        subNav: [
            {
                title: 'Global Parameters',
                headTitle: 'Global Parameters View',
                path: '/',
                
            },
        
        ]
    },
    {
        title: 'Consensus',
        // headTitle: '',
        path: '#',
        iconClosed: <RiIcons.RiArrowRightSFill />,
        iconOpened: <RiIcons.RiArrowDownSFill />,
        subNav: [
            {
                title: 'Global: 2021/03/01',
                headTitle: 'Global Consensus View',
                path: '/globalConsensusView',
                iconClosed: <RiIcons.RiArrowRightSFill />,
                iconOpened: <RiIcons.RiArrowDownSFill />,
                subNav: [
                    {
                        title: 'Bridge: 13:00:00',
                        headTitle: 'Bridge Consensus View',
                        path: '/bridgeConsensusView',
                        iconClosed: <RiIcons.RiArrowRightSFill />,
                        iconOpened: <RiIcons.RiArrowDownSFill />,
                        subNav: [
                            {
                                title: 'Sync List 2319',
                                headTitle: 'Sync List View',
                                path: '/syncListView',
                                iconClosed: <RiIcons.RiArrowRightSFill />,
                                iconOpened: <RiIcons.RiArrowDownSFill />,
                                subNav: [
                                    {
                                        title: 'Peer Ledger 871',
                                        headTitle: 'Peer Ledger View',
                                        path: '/peerLedgerDashboard',
                                        iconClosed: <RiIcons.RiArrowRightSFill />,
                                        iconOpened: <RiIcons.RiArrowDownSFill />,
                                        subNav: []
                                    },
                                    {
                                        title: 'Peer Ledger 273',
                                        headTitle: 'Peer Ledger View',
                                        path: '/peerLedgerDashboard',
                                        iconClosed: <RiIcons.RiArrowRightSFill />,
                                        iconOpened: <RiIcons.RiArrowDownSFill />,
                                        subNav: []
                                    },
                                    {
                                        title: 'Peer Ledger 1519',
                                        headTitle: 'Peer Ledger View',
                                        path: '/peerLedgerDashboard',
                                        iconClosed: <RiIcons.RiArrowRightSFill />,
                                        iconOpened: <RiIcons.RiArrowDownSFill />,
                                        subNav: []
                                    }
                                ]
                            },
                            {
                                title: 'Sync List 0071',
                                headTitle: 'Sync List View',
                                path: '/syncListView',
                                iconClosed: <RiIcons.RiArrowRightSFill />,
                                iconOpened: <RiIcons.RiArrowDownSFill />,
                                subNav: [
                                    {
                                        title: 'Peer Ledger 2831',
                                        headTitle: 'Peer Ledger View',
                                        path: '/peerLedgerDashboard',
                                        iconClosed: <RiIcons.RiArrowRightSFill />,
                                        iconOpened: <RiIcons.RiArrowDownSFill />,
                                        subNav: []
                                    }
                                ]
                            },
                            {
                                title: 'Sync List 0886',
                                headTitle: 'Sync List View',
                                path: '/syncListView',
                                iconClosed: <RiIcons.RiArrowRightSFill />,
                                iconOpened: <RiIcons.RiArrowDownSFill />,
                                subNav: [
                                    {
                                        title: 'Peer Ledger 2831',
                                        headTitle: 'Peer Ledger View',
                                        path: '/peerLedgerDashboard',
                                        iconClosed: <RiIcons.RiArrowRightSFill />,
                                        iconOpened: <RiIcons.RiArrowDownSFill />,
                                        subNav: []
                                    }
                                ]
                            },
                            {
                                title: 'Sync List 0034',
                                headTitle: 'Sync List View',
                                path: '/syncListView',
                                iconClosed: <RiIcons.RiArrowRightSFill />,
                                iconOpened: <RiIcons.RiArrowDownSFill />,
                                subNav: [
                                    {
                                        title: 'Peer Ledger 2831',
                                        headTitle: 'Peer Ledger View',
                                        path: '/peerLedgerDashboard',
                                        iconClosed: <RiIcons.RiArrowRightSFill />,
                                        iconOpened: <RiIcons.RiArrowDownSFill />,
                                        subNav: []
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        title: 'Bridge: 14:00:00',
                        headTitle: 'Bridge Consensus View',
                        path: '/bridgeConsensusView',
                        iconClosed: <RiIcons.RiArrowRightSFill />,
                        iconOpened: <RiIcons.RiArrowDownSFill />,
                        subNav: [
                            {
                                title: 'Sync List 3456',
                                headTitle: 'Sync List View',
                                path: '/syncListView',
                                iconClosed: <RiIcons.RiArrowRightSFill />,
                                iconOpened: <RiIcons.RiArrowDownSFill />,
                                subNav: [
                                    {
                                        title: 'Peer Ledger 871',
                                        headTitle: 'Peer Ledger View',
                                        path: '/peerLedgerDashboard',
                                        iconClosed: <RiIcons.RiArrowRightSFill />,
                                        iconOpened: <RiIcons.RiArrowDownSFill />,
                                        subNav: []
                                    }
                                ]
                            },
                            {
                                title: 'Sync List 5463',
                                headTitle: 'Sync List View',
                                path: '/syncListView',
                                iconClosed: <RiIcons.RiArrowRightSFill />,
                                iconOpened: <RiIcons.RiArrowDownSFill />,
                                subNav: [
                                    {
                                        title: 'Peer Ledger 2831',
                                        headTitle: 'Peer Ledger View',
                                        path: '/peerLedgerDashboard',
                                        iconClosed: <RiIcons.RiArrowRightSFill />,
                                        iconOpened: <RiIcons.RiArrowDownSFill />,
                                        subNav: []
                                    }
                                ]
                            },{
                                title: 'Sync List 455',
                                headTitle: 'Sync List View',
                                path: '/syncListView',
                                iconClosed: <RiIcons.RiArrowRightSFill />,
                                iconOpened: <RiIcons.RiArrowDownSFill />,
                                subNav: [
                                    {
                                        title: 'Peer Ledger 2831',
                                        headTitle: 'Peer Ledger View',
                                        path: '/peerLedgerDashboard',
                                        iconClosed: <RiIcons.RiArrowRightSFill />,
                                        iconOpened: <RiIcons.RiArrowDownSFill />,
                                        subNav: []
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        title: 'Bridge 15:00:00',
                        headTitle: 'Bridge Consensus View',
                        path: '/bridgeConsensusView',
                        iconClosed: <RiIcons.RiArrowRightSFill />,
                        iconOpened: <RiIcons.RiArrowDownSFill />,
                        subNav: [
                            {
                                title: 'Sync List 345',
                                headTitle: 'Sync List View',
                                path: '/syncListView',
                                iconClosed: <RiIcons.RiArrowRightSFill />,
                                iconOpened: <RiIcons.RiArrowDownSFill />,
                                subNav: [
                                    {
                                        title: 'Peer Ledger 456',
                                        headTitle: 'Peer Ledger View',
                                        path: '/peerLedgerDashboard',
                                        iconClosed: <RiIcons.RiArrowRightSFill />,
                                        iconOpened: <RiIcons.RiArrowDownSFill />,
                                        subNav: []
                                    }
                                ]
                            },
                            {
                                title: 'Sync List 2455',
                                headTitle: 'Sync List View',
                                path: '/syncListView',
                                iconClosed: <RiIcons.RiArrowRightSFill />,
                                iconOpened: <RiIcons.RiArrowDownSFill />,
                                subNav: [
                                    {
                                        title: 'Peer Ledger 2831',
                                        headTitle: 'Peer Ledger View',
                                        path: '/peerLedgerDashboard',
                                        iconClosed: <RiIcons.RiArrowRightSFill />,
                                        iconOpened: <RiIcons.RiArrowDownSFill />,
                                        subNav: []
                                    }
                                ]
                            },{
                                title: 'Sync List 7654',
                                headTitle: 'Sync List View',
                                path: '/syncListView',
                                iconClosed: <RiIcons.RiArrowRightSFill />,
                                iconOpened: <RiIcons.RiArrowDownSFill />,
                                subNav: [
                                    {
                                        title: 'Peer Ledger 2831',
                                        headTitle: 'Peer Ledger View',
                                        path: '/peerLedgerDashboard',
                                        iconClosed: <RiIcons.RiArrowRightSFill />,
                                        iconOpened: <RiIcons.RiArrowDownSFill />,
                                        subNav: []
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        title: 'Bridge: 16:00:00',
                        headTitle: 'Bridge Consensus View',
                        path: '/bridgeConsensusView',
                        iconClosed: <RiIcons.RiArrowRightSFill />,
                        iconOpened: <RiIcons.RiArrowDownSFill />,
                        subNav: [
                            {
                                title: 'Sync List 4567',
                                headTitle: 'Sync List View',
                                path: '/syncListView',
                                iconClosed: <RiIcons.RiArrowRightSFill />,
                                iconOpened: <RiIcons.RiArrowDownSFill />,
                                subNav: [
                                    {
                                        title: 'Peer Ledger 871',
                                        headTitle: 'Peer Ledger View',
                                        path: '/peerLedgerDashboard',
                                        iconClosed: <RiIcons.RiArrowRightSFill />,
                                        iconOpened: <RiIcons.RiArrowDownSFill />,
                                        subNav: []
                                    }
                                ]
                            },
                            {
                                title: 'Sync List 4857',
                                headTitle: 'Sync List View',
                                path: '/syncListView',
                                iconClosed: <RiIcons.RiArrowRightSFill />,
                                iconOpened: <RiIcons.RiArrowDownSFill />,
                                subNav: [
                                    {
                                        title: 'Peer Ledger 2831',
                                        headTitle: 'Peer Ledger View',
                                        path: '/peerLedgerDashboard',
                                        iconClosed: <RiIcons.RiArrowRightSFill />,
                                        iconOpened: <RiIcons.RiArrowDownSFill />,
                                        subNav: []
                                    }
                                ]
                            },{
                                title: 'Sync List 2389',
                                headTitle: 'Sync List View',
                                path: '/syncListView',
                                iconClosed: <RiIcons.RiArrowRightSFill />,
                                iconOpened: <RiIcons.RiArrowDownSFill />,
                                subNav: [
                                    {
                                        title: 'Peer Ledger 2831',
                                        headTitle: 'Peer Ledger View',
                                        path: '/peerLedgerDashboard',
                                        iconClosed: <RiIcons.RiArrowRightSFill />,
                                        iconOpened: <RiIcons.RiArrowDownSFill />,
                                        subNav: []
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        title: 'Bridge: 17:00:00',
                        headTitle: 'Bridge Consensus View',
                        path: '/bridgeConsensusView',
                        iconClosed: <RiIcons.RiArrowRightSFill />,
                        iconOpened: <RiIcons.RiArrowDownSFill />,
                        subNav: [
                            {
                                title: 'Sync List 2319',
                                headTitle: 'Sync List View',
                                path: '/syncListView',
                                iconClosed: <RiIcons.RiArrowRightSFill />,
                                iconOpened: <RiIcons.RiArrowDownSFill />,
                                subNav: [
                                    {
                                        title: 'Peer Ledger 871',
                                        headTitle: 'Peer Ledger View',
                                        path: '/peerLedgerDashboard',
                                        iconClosed: <RiIcons.RiArrowRightSFill />,
                                        iconOpened: <RiIcons.RiArrowDownSFill />,
                                        subNav: []
                                    }
                                ]
                            },
                            {
                                title: 'Sync List 831',
                                headTitle: 'Sync List View',
                                path: '/syncListView',
                                iconClosed: <RiIcons.RiArrowRightSFill />,
                                iconOpened: <RiIcons.RiArrowDownSFill />,
                                subNav: [
                                    {
                                        title: 'Peer Ledger 2831',
                                        headTitle: 'Peer Ledger View',
                                        path: '/peerLedgerDashboard',
                                        iconClosed: <RiIcons.RiArrowRightSFill />,
                                        iconOpened: <RiIcons.RiArrowDownSFill />,
                                        subNav: []
                                    }
                                ]
                            },{
                                title: 'Sync List 831',
                                headTitle: 'Sync List View',
                                path: '/syncListView',
                                iconClosed: <RiIcons.RiArrowRightSFill />,
                                iconOpened: <RiIcons.RiArrowDownSFill />,
                                subNav: [
                                    {
                                        title: 'Peer Ledger 2831',
                                        headTitle: 'Peer Ledger View',
                                        path: '/peerLedgerDashboard',
                                        iconClosed: <RiIcons.RiArrowRightSFill />,
                                        iconOpened: <RiIcons.RiArrowDownSFill />,
                                        subNav: []
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        title: 'Bridge: 18:00:00',
                        headTitle: 'Bridge Consensus View',
                        path: '/bridgeConsensusView',
                        iconClosed: <RiIcons.RiArrowRightSFill />,
                        iconOpened: <RiIcons.RiArrowDownSFill />,
                        subNav: [
                            {
                                title: 'Sync List 2319',
                                headTitle: 'Sync List View',
                                path: '/syncListView',
                                iconClosed: <RiIcons.RiArrowRightSFill />,
                                iconOpened: <RiIcons.RiArrowDownSFill />,
                                subNav: [
                                    {
                                        title: 'Peer Ledger 871',
                                        headTitle: 'Peer Ledger View',
                                        path: '/peerLedgerDashboard',
                                        iconClosed: <RiIcons.RiArrowRightSFill />,
                                        iconOpened: <RiIcons.RiArrowDownSFill />,
                                        subNav: []
                                    }
                                ]
                            },
                            {
                                title: 'Sync List 831',
                                headTitle: 'Sync List View',
                                path: '/syncListView',
                                iconClosed: <RiIcons.RiArrowRightSFill />,
                                iconOpened: <RiIcons.RiArrowDownSFill />,
                                subNav: [
                                    {
                                        title: 'Peer Ledger 2831',
                                        headTitle: 'Peer Ledger View',
                                        path: '/peerLedgerDashboard',
                                        iconClosed: <RiIcons.RiArrowRightSFill />,
                                        iconOpened: <RiIcons.RiArrowDownSFill />,
                                        subNav: []
                                    }
                                ]
                            },{
                                title: 'Sync List 831',
                                headTitle: 'Sync List View',
                                path: '/syncListView',
                                iconClosed: <RiIcons.RiArrowRightSFill />,
                                iconOpened: <RiIcons.RiArrowDownSFill />,
                                subNav: [
                                    {
                                        title: 'Peer Ledger 2831',
                                        headTitle: 'Peer Ledger View',
                                        path: '/peerLedgerDashboard',
                                        iconClosed: <RiIcons.RiArrowRightSFill />,
                                        iconOpened: <RiIcons.RiArrowDownSFill />,
                                        subNav: []
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        title: 'Bridge: 19:00:00',
                        headTitle: 'Bridge Consensus View',
                        path: '/bridgeConsensusView',
                        iconClosed: <RiIcons.RiArrowRightSFill />,
                        iconOpened: <RiIcons.RiArrowDownSFill />,
                        subNav: [
                            {
                                title: 'Sync List 2319',
                                headTitle: 'Sync List View',
                                path: '/syncListView',
                                iconClosed: <RiIcons.RiArrowRightSFill />,
                                iconOpened: <RiIcons.RiArrowDownSFill />,
                                subNav: [
                                    {
                                        title: 'Peer Ledger 871',
                                        headTitle: 'Peer Ledger View',
                                        path: '/peerLedgerDashboard',
                                        iconClosed: <RiIcons.RiArrowRightSFill />,
                                        iconOpened: <RiIcons.RiArrowDownSFill />,
                                        subNav: []
                                    }
                                ]
                            },
                            {
                                title: 'Sync List 831',
                                headTitle: 'Sync List View',
                                path: '/syncListView',
                                iconClosed: <RiIcons.RiArrowRightSFill />,
                                iconOpened: <RiIcons.RiArrowDownSFill />,
                                subNav: [
                                    {
                                        title: 'Peer Ledger 2831',
                                        headTitle: 'Peer Ledger View',
                                        path: '/peerLedgerDashboard',
                                        iconClosed: <RiIcons.RiArrowRightSFill />,
                                        iconOpened: <RiIcons.RiArrowDownSFill />,
                                        subNav: []
                                    }
                                ]
                            },{
                                title: 'Sync List 831',
                                headTitle: 'Sync List View',
                                path: '/syncListView',
                                iconClosed: <RiIcons.RiArrowRightSFill />,
                                iconOpened: <RiIcons.RiArrowDownSFill />,
                                subNav: [
                                    {
                                        title: 'Peer Ledger 2831',
                                        headTitle: 'Peer Ledger View',
                                        path: '/peerLedgerDashboard',
                                        iconClosed: <RiIcons.RiArrowRightSFill />,
                                        iconOpened: <RiIcons.RiArrowDownSFill />,
                                        subNav: []
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        title: 'Bridge: 20:00:00',
                        headTitle: 'Bridge Consensus View',
                        path: '/bridgeConsensusView',
                        iconClosed: <RiIcons.RiArrowRightSFill />,
                        iconOpened: <RiIcons.RiArrowDownSFill />,
                        subNav: [
                            {
                                title: 'Sync List 2319',
                                headTitle: 'Sync List View',
                                path: '/syncListView',
                                iconClosed: <RiIcons.RiArrowRightSFill />,
                                iconOpened: <RiIcons.RiArrowDownSFill />,
                                subNav: [
                                    {
                                        title: 'Peer Ledger 871',
                                        headTitle: 'Peer Ledger View',
                                        path: '/peerLedgerDashboard',
                                        iconClosed: <RiIcons.RiArrowRightSFill />,
                                        iconOpened: <RiIcons.RiArrowDownSFill />,
                                        subNav: []
                                    }
                                ]
                            },
                            {
                                title: 'Sync List 831',
                                headTitle: 'Sync List View',
                                path: '/syncListView',
                                iconClosed: <RiIcons.RiArrowRightSFill />,
                                iconOpened: <RiIcons.RiArrowDownSFill />,
                                subNav: [
                                    {
                                        title: 'Peer Ledger 2831',
                                        headTitle: 'Peer Ledger View',
                                        path: '/peerLedgerDashboard',
                                        iconClosed: <RiIcons.RiArrowRightSFill />,
                                        iconOpened: <RiIcons.RiArrowDownSFill />,
                                        subNav: []
                                    }
                                ]
                            },{
                                title: 'Sync List 831',
                                headTitle: 'Sync List View',
                                path: '/syncListView',
                                iconClosed: <RiIcons.RiArrowRightSFill />,
                                iconOpened: <RiIcons.RiArrowDownSFill />,
                                subNav: [
                                    {
                                        title: 'Peer Ledger 2831',
                                        headTitle: 'Peer Ledger View',
                                        path: '/peerLedgerDashboard',
                                        iconClosed: <RiIcons.RiArrowRightSFill />,
                                        iconOpened: <RiIcons.RiArrowDownSFill />,
                                        subNav: []
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        title: 'Bridge: 21:00:00',
                        headTitle: 'Bridge Consensus View',
                        path: '/bridgeConsensusView',
                        iconClosed: <RiIcons.RiArrowRightSFill />,
                        iconOpened: <RiIcons.RiArrowDownSFill />,
                        subNav: [
                            {
                                title: 'Sync List 2319',
                                headTitle: 'Sync List View',
                                path: '/syncListView',
                                iconClosed: <RiIcons.RiArrowRightSFill />,
                                iconOpened: <RiIcons.RiArrowDownSFill />,
                                subNav: [
                                    {
                                        title: 'Peer Ledger 871',
                                        headTitle: 'Peer Ledger View',
                                        path: '/peerLedgerDashboard',
                                        iconClosed: <RiIcons.RiArrowRightSFill />,
                                        iconOpened: <RiIcons.RiArrowDownSFill />,
                                        subNav: []
                                    }
                                ]
                            },
                            {
                                title: 'Sync List 831',
                                headTitle: 'Sync List View',
                                path: '/syncListView',
                                iconClosed: <RiIcons.RiArrowRightSFill />,
                                iconOpened: <RiIcons.RiArrowDownSFill />,
                                subNav: [
                                    {
                                        title: 'Peer Ledger 2831',
                                        headTitle: 'Peer Ledger View',
                                        path: '/peerLedgerDashboard',
                                        iconClosed: <RiIcons.RiArrowRightSFill />,
                                        iconOpened: <RiIcons.RiArrowDownSFill />,
                                        subNav: []
                                    }
                                ]
                            },{
                                title: 'Sync List 831',
                                headTitle: 'Sync List View',
                                path: '/syncListView',
                                iconClosed: <RiIcons.RiArrowRightSFill />,
                                iconOpened: <RiIcons.RiArrowDownSFill />,
                                subNav: [
                                    {
                                        title: 'Peer Ledger 2831',
                                        headTitle: 'Peer Ledger View',
                                        path: '/peerLedgerDashboard',
                                        iconClosed: <RiIcons.RiArrowRightSFill />,
                                        iconOpened: <RiIcons.RiArrowDownSFill />,
                                        subNav: []
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }, 
            {
                title: 'Global: 2021/03/02',
                headTitle: 'Global Consensus View',
                path: '/globalConsensusView',
                iconClosed: <RiIcons.RiArrowRightSFill />,
                iconOpened: <RiIcons.RiArrowDownSFill />,
                subNav: [
                    {
                        title: 'Bridge 31',
                        headTitle: 'Bridge Consensus View',
                        path: '/bridgeConsensusView',
                        iconClosed: <RiIcons.RiArrowRightSFill />,
                        iconOpened: <RiIcons.RiArrowDownSFill />,
                        subNav: [
                            {
                                title: 'Sync List 831',
                                headTitle: 'Sync List View',
                                path: '/syncListView',
                                iconClosed: <RiIcons.RiArrowRightSFill />,
                                iconOpened: <RiIcons.RiArrowDownSFill />,
                                subNav: [
                                    {
                                        title: 'Peer Ledger 2831',
                                        headTitle: 'Peer Ledger View',
                                        path: '/peerLedgerDashboard',
                                        iconClosed: <RiIcons.RiArrowRightSFill />,
                                        iconOpened: <RiIcons.RiArrowDownSFill />,
                                        subNav: []
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                title: 'Global: 2021/03/03',
                headTitle: 'Global Consensus View',
                path: '/globalConsensusView',
                iconClosed: <RiIcons.RiArrowRightSFill />,
                iconOpened: <RiIcons.RiArrowDownSFill />,
                subNav: [
                    {
                        title: 'Bridge 21',
                        headTitle: 'Bridge Consensus View',
                        path: '/bridgeConsensusView',
                        iconClosed: <RiIcons.RiArrowRightSFill />,
                        iconOpened: <RiIcons.RiArrowDownSFill />,
                    }
                ]
            }, 
            {
                title: 'Global: 2021/03/04',
                headTitle: 'Global Consensus View',
                path: '/globalConsensusView',
                iconClosed: <RiIcons.RiArrowRightSFill />,
                iconOpened: <RiIcons.RiArrowDownSFill />,
                subNav: [
                    {
                        title: 'Bridge 21',
                        headTitle: 'Bridge Consensus View',
                        path: '/bridgeConsensusView',
                        iconClosed: <RiIcons.RiArrowRightSFill />,
                        iconOpened: <RiIcons.RiArrowDownSFill />,
                    }
                ]
            }, 
            {
                title: 'Global: 2021/03/05',
                headTitle: 'Global Consensus View',
                path: '/globalConsensusView',
                iconClosed: <RiIcons.RiArrowRightSFill />,
                iconOpened: <RiIcons.RiArrowDownSFill />,
                subNav: [
                    {
                        title: 'Bridge 21',
                        headTitle: 'Bridge Consensus View',
                        path: '/bridgeConsensusView',
                        iconClosed: <RiIcons.RiArrowRightSFill />,
                        iconOpened: <RiIcons.RiArrowDownSFill />,
                    }
                ]
            }, 
            {
                title: 'Global: 2021/03/06',
                headTitle: 'Global Consensus View',
                path: '/globalConsensusView',
                iconClosed: <RiIcons.RiArrowRightSFill />,
                iconOpened: <RiIcons.RiArrowDownSFill />,
                subNav: [
                    {
                        title: 'Bridge 21',
                        headTitle: 'Bridge Consensus View',
                        path: '/bridgeConsensusView',
                        iconClosed: <RiIcons.RiArrowRightSFill />,
                        iconOpened: <RiIcons.RiArrowDownSFill />,
                    }
                ]
            }, 
            {
                title: 'Global: 2021/03/07',
                headTitle: 'Global Consensus View',
                path: '/globalConsensusView',
                iconClosed: <RiIcons.RiArrowRightSFill />,
                iconOpened: <RiIcons.RiArrowDownSFill />,
                subNav: [
                    {
                        title: 'Bridge 21',
                        headTitle: 'Bridge Consensus View',
                        path: '/bridgeConsensusView',
                        iconClosed: <RiIcons.RiArrowRightSFill />,
                        iconOpened: <RiIcons.RiArrowDownSFill />,
                    }
                ]
            }, 
        ]
    }
]