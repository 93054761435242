import { Chart } from "chart.js";
import React, { useState, useEffect, useContext } from "react";
import { Line } from "react-chartjs-2";

import { GlobalsContext } from "../../../contexts/globals.context";
import GLOBAL_STATS from "../../../data/globalStats.data";

function GlobalLineChart({ globalData, globalsData }) {
  const { selectedGlobal } = useContext(GlobalsContext);
  let chartGlobals = [];
  const revGlobals = [];

  globalsData.map((item) => {
    GLOBAL_STATS.forEach((stat) => {
      if (stat.BlockNumber == item.BlockNumber) {
        chartGlobals.push(stat);
      }
    });
  });
  chartGlobals.map((item) => revGlobals.unshift(item)).reverse();
  const data = {
    labels: revGlobals.map((item) =>
      new Date(item.UnixTime * 1000).toLocaleDateString()
    ),
    datasets: [
      {
        label: "Global Phyli Balance",
        data: revGlobals.map((item) => item.GlobalPhyliBal),
        lineTension: 0.01,
        borderColor: ["rgba(45, 206, 86, 0.4)"],
        backgroundColor: ["rgba(45, 206, 86, 0.2)"],
        pointBackgroundColor: revGlobals.map((item) =>
          item.BlockNumber == selectedGlobal
            ? ["rgba(255, 0, 0, 0.6"]
            : ["rgba(45, 206, 86, 0.2)"]
        ),
        pointBorderColor: revGlobals.map((item) =>
          item.BlockNumber == selectedGlobal
            ? ["rgba(255, 0, 0, 0.6"]
            : ["rgba(45, 206, 86, 0.2)"]
        ),
        pointRadius: revGlobals.map((item) =>
          item.BlockNumber == selectedGlobal ? 6 : 2
        ),
      },

      {
        label: "Global Phyli Holds",
        data: revGlobals.map((item) => item.GlobalPhyliHold),
        lineTension: 0.01,
        borderColor: ["rgba(45, 86, 206, 0.4"],
        backgroundColor: ["rgba(45, 86, 206, 0.2"],
        pointBackgroundColor: revGlobals.map((item) =>
          item.BlockNumber == selectedGlobal
            ? ["rgba(255, 0, 0, 0.6"]
            : ["rgba(45, 206, 86, 0.2)"]
        ),
        pointBorderColor: revGlobals.map((item) =>
          item.BlockNumber == selectedGlobal
            ? ["rgba(255, 0, 0, 0.6"]
            : ["rgba(45, 206, 86, 0.2)"]
        ),
        pointRadius: revGlobals.map((item) =>
          item.BlockNumber == selectedGlobal ? 6 : 2
        ),
      },
    ],
  };

  const options = {
    title: {
      display: false,
      text: "Active Phyli",
    },

    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
            max: 4000000,
            stepsize: 1,
          },
        },
      ],
      xAxes: [
        {
          label: { color: "red" },
        },
      ],
    },
  };

  return (
    <div className='chart-size'>
      <Line data={data} options={options} height='200' />
    </div>
  );
}

export default GlobalLineChart;
