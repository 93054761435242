import React, { createContext, useContext, useState } from "react";

import { BridgesContext } from "./Bridges.context";

export const PrimaryContext = createContext();

const PrimaryContextProvider = (props) => {
  const [primaryList, setPrimaryList] = useState([
    {
      isLoading: true,
      isError: false,
      bridge: 0,
      peerNum: 0,
      primaryData: [],
    },
  ]);
  return (
    <PrimaryContext.Provider value={{ primaryList, setPrimaryList }}>
      {props.children}
    </PrimaryContext.Provider>
  );
};

export const useFetchPrimary = () => {
  const { primaryList, setPrimaryList } = useContext(PrimaryContext);
  const { bridges } = useContext(BridgesContext);
  let newIndex = -1;

  const fetchPrimary = async (blockNumber, peerNumber) => {
    bridges.map((bridge, index) => {
      bridge.bridgeData.map((item) => {
        if (item.BlockNumber == blockNumber) {
          newIndex = index;
        }
      });
    });

    try {
      const setGlobal = await fetch(
        `https://api.philos.us:42074/global/${bridges[newIndex].global}`
      );
      const setBridge = await fetch(
        `https://api.philos.us:42074/bridge/${blockNumber}`
      );
      const resPrimary = await fetch(
        `https://api.philos.us:42074/primary/range`
      );
      const primaryData = await resPrimary.json();
      if (primaryList.isLoading) {
        console.log("Loading primary list");
      }
      if (primaryData != "{Error: RNF}") {
        setPrimaryList([
          {
            isLoading: false,
            isError: false,
            isClicked: false,
            bridge: blockNumber,
            peerNumber: peerNumber,
            primaryData: primaryData,
          },
        ]);
      } else {
        setPrimaryList([
          {
            isLoading: false,
            isError: false,
            isClicked: false,
            bridge: blockNumber,
            peerNumber: peerNumber,
            primaryData: [],
          },
        ]);
      }
    } catch (error) {
      setPrimaryList([
        {
          isLoading: false,
          isError: error,
          isClicked: false,
          bridge: 0,
          peerNumber: 0,
          primaryData: [],
        },
      ]);
    }
  };
  return {
    primaryList: primaryList,
    setPrimaryList: setPrimaryList,
    fetchPrimary: fetchPrimary,
  };
};
export default PrimaryContextProvider;
