import React, { useState } from "react";
import * as RiIcons from "react-icons/ri";

import "./Slider.styles.scss";

import PeerLedgerBCView from "../../pages/peerLedgerBCView/PeerLedgerBCView.component";
import PeerLedgerDBView from "../../pages/peerLedgerDBView/PeerLedgerDBView.component";

const SliderContainer = ({ slider }) => {
  const [slideMenu, setSlideMenu] = useState(false);

  const toggleMenu = () => {
    setSlideMenu(!slideMenu);
  };
  return (
    <div className={`slide-container ${slider.sliderButton ? "hide" : "show"}`}>
      <div className='slide-inner-container'>
        <div className='close'>
          <button className='button-close'>
            <RiIcons.RiCloseFill onClick={() => slider.setSlider(!slider.sliderButton)} />
          </button>
        </div>

        <PeerLedgerBCView />
      </div>

      {/* <div>Peer</div>
      <div>Content</div> */}
    </div>
  );
};

export default SliderContainer;
