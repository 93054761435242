import { interpolateRgbBasis } from 'd3-interpolate';
import React from 'react'
import { Bar } from 'react-chartjs-2'; 

function PeerLedgerBar() {

    const data = {
        labels: [1500, 1510, 1520, 1530, 1540, 1550, 1600, 1610, 1620, 1630, 1640, 1650, 1700, 1710, 1720, 1730, 1740, 1750, 1800, 1810, 1820, 1830, 1840, 1850,
            1900, 1910, 1920, 1930, 1940, 1950, 2000, 2010, 2020, 2030, 2040, 2050, 2100, 2110, 2120, 2130, 2140, 2150, 2200, 2210, 2220, 2230, 2240, 2250,2300],
        datasets: [
            {
                label: 871,
                barPercentage: 0.1,
                barThickness: 10,
                maxBarThickness: 20,
                minBarLength: 2,
                data: [150, 11, 25, 33, 75, 85, 38, 42, 54, 62, 78, 44, 98, 43, 27, 64, 125, 114, 128, 99, 76, 68, 121, 98, 105, 54, 63, 78, 106, 79, 103, 127, 89, 65, 108, 110, 48, 97, 112, 123, 96, 57, 69, 72, 56, 156, 221, 79, 64, 79, 99],
                backgroundColor: "rgba(0, 0, 255, 0.6)",
                onClick: function(event) {
                    var element = document.event.target;
                    alert(element.value)
                }

            },

            {
                label: 273,
                barPercentage: 0.1,
                barThickness: 10,
                maxBarThickness: 20,
                minBarLength: 2,
                data: [57, 11, 25, 33, 75, 85, 38, 125, 78, 99, 145, 79, 86, 127, 105, 98, 156, 175, 46, 78, 87, 176, 65, 189, 89, 97, 65, 79, 67, 56, 112, 49, 98, 76, 77, 43, 122, 110, 98, 97, 67, 158,76, 92, 67, 87, 112, 143, 56],
                backgroundColor: 'rgba(255, 0, 0, 0.6)'

            },
            {
                label: 1519,
                barPercentage: 0.1,
                barThickness: 10,
                maxBarThickness: 20,
                minBarLength: 2,
                data: [152, 11, 25, 33, 75, 85, 38, 27, 145, 97, 56, 69, 98, 74, 64, 112, 125, 89, 97, 112, 110, 67, 98, 76, 45, 122, 87, 90, 65, 76, 89, 97, 23, 45, 123, 156, 98, 100, 39, 67, 76, 89, 87, 88, 65, 47, 76, 87, 92],
                backgroundColor: "rgba(0, 255, 0, 0.6)"

            },
        ]
    }

    const options = {
        // title: {
        //     display: true, 
        //     text: 'Bridge Counts',
        // },
        scales: {
            xAxes: [{stacked: true}],
            yAxes: [{stacked: true}],
        },
        responsive: true,
        maintainAspectRatio: false,
    }

    return (
        <div className="chart-size" >
            <Bar data={data} options={options} height={500} width={700}/>
        </div>
    )
}

export default PeerLedgerBar;
