import React, { createContext, useState, useContext, useEffect } from "react";
import { GliceContext } from "./glice/glice.context";

export const GlobalsContext = createContext([]);

const GlobalsContextProvider = (props) => {
  const [blockLast, setBlockLast] = useState({
    isLoading: true,
    isError: false,
    blockLastData: [],
  });
  const [globals, setGlobals] = useState({
    isLoading: true,
    isError: false,
    globalData: [],
  });

  const [selectedGlobal, setSelectedGlobal] = useState(0);

  return (
    <GlobalsContext.Provider
      value={{
        blockLast,
        setBlockLast,
        globals,
        setGlobals,
        selectedGlobal,
        setSelectedGlobal,
      }}
    >
      {props.children}
    </GlobalsContext.Provider>
  );
};

export const useFetchLast = () => {
  const { blockLast, setBlockLast } = useContext(GlobalsContext);
  // const { glice, setGlice } = useContext(GliceContext);

  const fetchData = async () => {
    try {
      const res = await fetch("https://api.philos.us:42074/last");
      const data = await res.json();
      if (blockLast.isLoading) {
        console.log("Loading last data");
      }
      setBlockLast({
        isLoading: false,
        isError: false,
        blockLastData: data,
      });
      // setGlice({
      //   ...glice,
      //   blockLast: { isLoading: false, isError: false, lastData: data },
      // });
    } catch (error) {
      setBlockLast({
        isLoading: false,
        isError: true,
        blockLastData: null,
      });
      // setGlice({
      //   ...glice,
      //   blockLast: { isLoading: false, isError: error, lastData: null },
      // });
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { blockLast: blockLast, getBlockLast: fetchData };
};

export const useFetchGlobal = () => {
  // const { setGlice } = useContext(GliceContext);
  const { globals, setGlobals } = useContext(GlobalsContext);
  const { blockLast, getBlockLast } = useContext(GlobalsContext);

  const fetchGlobal = async () => {
    if (blockLast.blockLastData != []) {
      if (globals.isLoading) {
        console.log("Loading Global Data");
      }
      try {
        const res = await fetch("https://api.philos.us:42074/global/pageDown");
        const globalsData = await res.json();

        setGlobals({
          isLoading: false,
          isError: false,
          globalData: globalsData,
        });
      } catch (error) {
        setGlobals({
          isLoading: false,
          isError: true,
          globalData: null,
        });
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchGlobal();
  }, []);
  return globals;
};

export default GlobalsContextProvider;
