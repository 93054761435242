import React, { createContext, useContext, useState } from "react";

import { BridgesContext } from "./Bridges.context";

export const ContentContext = createContext();

const ContentContextProvider = (props) => {
  const [contentList, setContentList] = useState([
    {
      isLoading: true,
      isError: false,
      isClicked: false,
      bridge: 0,
      contentData: [],
    },
  ]);

  return <ContentContext.Provider value={{ contentList, setContentList }}>{props.children}</ContentContext.Provider>;
};

export const useFetchContent = () => {
  const { contentList, setContentList } = useContext(ContentContext);

  const { bridges } = useContext(BridgesContext);

  let newIndex = -1;

  const fetchContent = async (blockNumber) => {
    bridges.map((bridge, index) => {
      bridge.bridgeData.map((item) => {
        if (item.BlockNumber == blockNumber) {
          newIndex = index;
        }
      });
    });
    try {
      const setGlobal = await fetch(`https://api.philos.us:42074/global/${bridges[newIndex].global}`);
      const setBridge = await fetch(`https://api.philos.us:42074/bridge/${blockNumber}`);
      const setLast = await fetch(`https://api.philos.us:42074/content/last`);
      const resContent = await fetch(`https://api.philos.us:42074/content/pageDown`);
      console.log(resContent);
      const contentData = await resContent.json();

      if (contentList.isLoading) {
        console.log("Loading content...");
      }

      if (contentData != "{Error: RNF}") {
        setContentList([
          {
            isLoading: false,
            isError: false,
            isClicked: false,
            bridge: blockNumber,
            contentData: contentData,
          },
        ]);
      } else {
        setContentList([
          {
            isLoading: false,
            isError: false,
            isClicked: false,
            bridge: blockNumber,
            contentData: null,
          },
        ]);
      }
    } catch (error) {
      setContentList([
        {
          isLoading: false,
          isError: error,
          isClicked: false,
          bridge: 0,
          contentData: null,
        },
      ]);
    }
  };
  console.log(contentList);
  return {
    contentList: contentList,
    setContentList: setContentList,
    fetchContent: fetchContent,
  };
};

export default ContentContextProvider;
