import React, { createContext, useContext, useState } from "react";

import { GlobalsContext } from "./globals.context";
import { GliceContext } from "./glice/glice.context";

export const BridgesContext = createContext();

const BridgesContextProvider = (props) => {
  const [bridges, setBridges] = useState([
    {
      isLoading: true,
      isError: false,
      isClicked: false,
      global: 0,
      bridgeData: [],
    },
  ]);

  return (
    <BridgesContext.Provider value={{ bridges, setBridges }}>
      {props.children}
    </BridgesContext.Provider>
  );
};

export const useFetchBridges = () => {
  const { bridges, setBridges } = useContext(BridgesContext);
  const { globals, blockLast } = useContext(GlobalsContext);
  // const { glice, setGlice } = useContext(GliceContext);

  const fetchData = async (blockNumber) => {
    const currentLast = globals.globalData[0].BlockNumber;
    const setCurrent = await fetch(
      `https://api.philos.us:42074/global/${blockNumber}`
    );
    const resBridges = await fetch("https://api.philos.us:42074/bridge/pageUp");
    const bridgeData = await resBridges.json();
    if (bridges.isLoading) {
      console.log("Loading Bridges");
    }
    try {
      if (
        bridgeData != "{Error: BNF}" &&
        bridges.find((item) => item.global === blockNumber) === undefined
      ) {
        setBridges([
          ...bridges,
          {
            isLoading: false,
            isError: false,
            isClicked: false,
            global: blockNumber,
            bridgeData: bridgeData.reverse(),
          },
        ]);
      } else {
        setBridges([
          ...bridges,
          {
            isLoading: false,
            isError: false,
            isClicked: false,
            global: blockNumber,
            bridgeData: [],
          },
        ]);
      }
    } catch (error) {
      console.log(error);
      setBridges({
        isLoading: false,
        isError: error,
        isClicked: false,
        global: 0,
        bridgeData: null,
      });
    }
    const resetGlobal = await fetch(
      `https://api.philos.us:42074/global/${currentLast}`
    );
  };
  return { bridges: bridges, getBridges: fetchData, setBridges: setBridges };
};

export default BridgesContextProvider;
