import React from "react";
import * as RiIcons from "react-icons/ri";

function CreateDataModel(globals) {
  const globalList = [];

  globals.forEach(function (global, index) {
    globalList.push({
      title: `Global ${new Date(global.UnixTime * 1000).toLocaleDateString()}`,
      headTitle: "Global Consensus View",
      path: "/globalConsensusView",
      iconClosed: <RiIcons.RiArrowRightSFill />,
      iconOpened: <RiIcons.RiArrowDownSFill />,
      subNav: [],
    });
  });

  this.state.dataModel = [
    {
      title: "Configuration",
      path: "#",
      iconClosed: <RiIcons.RiArrowRightSFill />,
      iconOpened: <RiIcons.RiArrowDownSFill />,
      subNav: [
        {
          title: "Global Parameters",
          headTitle: "Global Parameters View",
          path: "/",
        },
      ],
    },
    {
      title: "Consensus",
      path: "#",
      iconClosed: <RiIcons.RiArrowRightSFill />,
      iconOpened: <RiIcons.RiArrowDownSFill />,
      subNav: [...globalList],
    },
  ];
}

export default CreateDataModel;
