import React from 'react'
import { Line } from 'react-chartjs-2'; 

function BridgeConsensuLine({treeData}) {
    const data = {
        labels: ["1pm", "2pm", "3pm", "4pm", "5pm", "6pm", "7pm", "8pm", "9pm"],
        datasets: [
            {
                label: 'Phyli Transferred',
                // data: [],
                data: [10, 4, 20, 30, 40, 20, 50, 22, 55],
                borderColor: ['rgba(255, 0, 0, 1.0)'],
                backgroundColor: ['rgba(255, 0, 0, 0.5)'],
                lineTension: 0.1,
                order: 2, 
                fill: true

            },
            {
                label: 'Available Phyli',
                data: [8, 2, 10, 25, 30, 15, 45, 20, 42],
                borderColor: ['rgba(0, 255, 0, 1.0)'],
                backgroundColor: ['rgba(0, 255, 0, 0.5)'],
                lineTension: 0.1,
                order: 1,
                fill: true
            },
            {
                label: 'Hold Phyli',
                data: [6, 0, 8, 23, 25, 9, 42, 18, 40],
                borderColor: ['rgba(0, 0, 255, 1.0)'],
                backgroundColor: ['rgba(0, 0, 255, 0.5)'],
                lineTension: 0.1,
                order: 0,
                fill: true
            },
            
        ]
    }

    const options = {
        responsive: true,
        title: {
            display: false, 
            text: 'Bridge Counts'
        },
        scales: {
            xAxes: [
                // {
                //     gridLines: {
                //         offsetGridLines: true
                //     }
                // }
            ],
            yAxes: [
                {
                    ticks: {
                        min: 0, 
                        max: 100,
                        stepsize: 1
                    }
                }
            ]
        }
    }

    return (
        <div className="chart-size">
            <Line data={data} options={options} height="175" />
        </div>
    )
}

export default BridgeConsensuLine;
