import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import GlobalsContextProvider from "./contexts/globals.context";
// import GliceContextProvider from "./contexts/glice/Glice.context";
import SelectedBlockContextProvider from "./contexts/SelectedBlock.context";
import PrimaryContextProvider from "./contexts/Primary.context";
import BridgesContextProvider from "./contexts/Bridges.context";
import SyncListContextProvider from "./contexts/SyncList.context";

import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BridgesContext } from "./contexts/Bridges.context";

ReactDOM.render(
  <React.StrictMode>
    {/* <GliceContextProvider> */}
    <GlobalsContextProvider>
      <BridgesContextProvider>
        <SyncListContextProvider>
          <PrimaryContextProvider>
            <SelectedBlockContextProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </SelectedBlockContextProvider>
          </PrimaryContextProvider>
        </SyncListContextProvider>
      </BridgesContextProvider>
    </GlobalsContextProvider>
    {/* </GliceContextProvider> */}
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
